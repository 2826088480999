export const jobstreetAlternateLinks = [
  {
    rel: 'alternate',
    hreflang: 'x-default',
    href: 'https://www.jobstreet.com/',
  },
  {
    rel: 'alternate',
    hreflang: 'id-id',
    href: 'https://id.jobstreet.com/',
  },
  {
    rel: 'alternate',
    hreflang: 'en-id',
    href: 'https://id.jobstreet.com/en',
  },
  {
    rel: 'alternate',
    hreflang: 'en-my',
    href: 'https://my.jobstreet.com/',
  },
  {
    rel: 'alternate',
    hreflang: 'en-sg',
    href: 'https://sg.jobstreet.com/',
  },
  {
    rel: 'alternate',
    hreflang: 'en-ph',
    href: 'https://ph.jobstreet.com/',
  },
];
