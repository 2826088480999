import type { SearchParams } from '@seek/chalice-types';
import type { Language } from '@seek/melways-sites';

interface MajorCity {
  name: string;
  query: SearchParams;
}

type MajorCitiesLinks = {
  [key in Language]?: MajorCity[];
};

export const majorCitiesLinks: Record<string, MajorCitiesLinks> = {
  'anz-1': {
    en: [
      {
        name: 'Sydney',
        query: { where: 'All Sydney NSW' },
      },
      {
        name: 'Melbourne',
        query: { where: 'All Melbourne VIC' },
      },
      {
        name: 'Brisbane',
        query: { where: 'All Brisbane QLD' },
      },
      {
        name: 'Gold Coast',
        query: { where: 'All Gold Coast QLD' },
      },
      {
        name: 'Perth',
        query: { where: 'All Perth WA' },
      },
      {
        name: 'Adelaide',
        query: { where: 'All Adelaide SA' },
      },
      {
        name: 'Hobart',
        query: { where: 'All Hobart TAS' },
      },
      {
        name: 'Darwin',
        query: { where: 'All Darwin NT' },
      },
      {
        name: 'Canberra',
        query: { where: 'All Canberra ACT' },
      },
    ],
  },
  'anz-2': {
    en: [
      {
        name: 'Auckland',
        query: { where: 'All Auckland' },
      },
      {
        name: 'Hamilton',
        query: { where: 'Hamilton Waikato' },
      },
      {
        name: 'Tauranga',
        query: { where: 'Tauranga Bay of Plenty' },
      },
      {
        name: 'Wellington',
        query: { where: 'All Wellington' },
      },
      {
        name: 'Christchurch',
        query: { where: 'Christchurch Canterbury' },
      },
      {
        name: 'Dunedin',
        query: { where: 'Dunedin Otago' },
      },
    ],
  },
  'asia-1': {
    en: [
      {
        name: 'Airport Area',
        query: { where: 'Airport Area Islands District' },
      },
      {
        name: 'Tung Chung',
        query: { where: 'Tung Chung Islands District' },
      },
      {
        name: 'To Kwa Wan',
        query: { where: 'To Kwa Wan Kowloon City District' },
      },
      {
        name: 'Sha Tin',
        query: { where: 'Sha Tin District' },
      },
      {
        name: 'Kwun Tong',
        query: { where: 'Kwun Tong District' },
      },
      {
        name: 'Eastern Area',
        query: { where: 'Eastern District' },
      },
      {
        name: 'Kwai Tsing',
        query: { where: 'Kwai Tsing Area' },
      },
      {
        name: 'Sham Shui Po',
        query: { where: 'Sham Shui Po District' },
      },
    ],
  },
  'asia-3': {
    en: [
      {
        name: 'Bangkok',
        query: { where: 'Bangkok' },
      },
      {
        name: 'Chiang Mai',
        query: { where: 'Chiang Mai' },
      },
      {
        name: 'Khon Kaen',
        query: { where: 'Khon Kaen' },
      },
      {
        name: 'Phuket',
        query: { where: 'Phuket' },
      },
      {
        name: 'Chiang Rai',
        query: { where: 'Chiang Rai' },
      },
      {
        name: 'Chonburi',
        query: { where: 'Chon Buri' },
      },
      {
        name: 'Saraburi',
        query: { where: 'Saraburi' },
      },
      {
        name: 'Samut Prakan',
        query: { where: 'Mueang Samut Prakan Samut Prakan' },
      },
    ],
    th: [
      {
        name: 'กรุงเทพมหานคร',
        query: { where: 'กรุงเทพมหานคร' },
      },
      {
        name: 'เชียงใหม่',
        query: { where: 'เชียงใหม่' },
      },
      {
        name: 'ขอนแก่น',
        query: { where: 'ขอนแก่น' },
      },
      {
        name: 'ภูเก็ต',
        query: { where: 'ภูเก็ต' },
      },
      {
        name: 'เชียงราย',
        query: { where: 'เชียงราย' },
      },
      {
        name: 'ชลบุรี',
        query: { where: 'ชลบุรี' },
      },
      {
        name: 'สระบุรี',
        query: { where: 'สระบุรี' },
      },
      {
        name: 'สมุทรปราการ',
        query: { where: 'สมุทรปราการ' },
      },
    ],
  },
  'asia-4': {
    en: [
      {
        name: 'Surabaya',
        query: { where: 'Surabaya East Java' },
      },
      {
        name: 'Bandung',
        query: { where: 'Bandung West Java' },
      },
      {
        name: 'Medan',
        query: { where: 'Medan North Sumatra' },
      },
      {
        name: 'Jakarta',
        query: { where: 'Jakarta' },
      },
      {
        name: 'Semarang',
        query: { where: 'Semarang Central Java' },
      },
      {
        name: 'Tangerang',
        query: { where: 'Tangerang Banten' },
      },
      {
        name: 'Malang',
        query: { where: 'Malang East Java' },
      },
      {
        name: 'Bekasi',
        query: { where: 'Bekasi West Java' },
      },
    ],
    id: [
      {
        name: 'Surabaya',
        query: { where: 'Surabaya Jawa Timur' },
      },
      {
        name: 'Bandung',
        query: { where: 'Bandung Jawa Barat' },
      },
      {
        name: 'Medan',
        query: { where: 'Medan Sumatera Utara' },
      },
      {
        name: 'Jakarta',
        query: { where: 'Jakarta Raya' },
      },
      {
        name: 'Semarang',
        query: { where: 'Semarang Jawa Tengah' },
      },
      {
        name: 'Tangerang',
        query: { where: 'Tangerang Banten' },
      },
      {
        name: 'Malang',
        query: { where: 'Malang Jawa Timur' },
      },
      {
        name: 'Bekasi',
        query: { where: 'Bekasi Jawa Barat' },
      },
    ],
  },
  'asia-5': {
    en: [
      {
        name: 'Penang',
        query: { where: 'Penang' },
      },
      {
        name: 'Kuching',
        query: { where: 'Kuching Sarawak' },
      },
      {
        name: 'Miri',
        query: { where: 'Miri Sarawak' },
      },
      {
        name: 'Kota Kinabalu',
        query: { where: 'Kota Kinabalu Sabah' },
      },
      {
        name: 'Ipoh',
        query: { where: 'Ipoh Perak' },
      },
      {
        name: 'Johor Bahru',
        query: { where: 'Johor Bahru Johor' },
      },
      {
        name: 'Kuala Lumpur',
        query: { where: 'Kuala Lumpur' },
      },
      {
        name: 'Klang',
        query: { where: 'Klang - Port Klang Selangor' },
      },
    ],
  },
  'asia-6': {
    en: [
      {
        name: 'Quezon City',
        query: { where: 'Quezon City Metro Manila' },
      },
      {
        name: 'Manila',
        query: { where: 'Manila City Metro Manila' },
      },
      {
        name: 'Taguig',
        query: { where: 'Taguig City Metro Manila' },
      },
      {
        name: 'Antipolo',
        query: { where: 'Antipolo City Rizal' },
      },
      {
        name: 'Las Pinas',
        query: { where: 'Las Pinas City Metro Manila' },
      },
      {
        name: 'Davao',
        query: { where: 'Davao' },
      },
      {
        name: 'Pasay',
        query: { where: 'Pasay City Metro Manila' },
      },
      {
        name: 'Cebu City',
        query: { where: 'Cebu City Cebu' },
      },
    ],
  },
  'asia-7': {
    en: [
      {
        name: 'Yishun',
        query: { where: 'Yishun North Region' },
      },
      {
        name: 'Woodlands',
        query: { where: 'Woodlands North Region' },
      },
      {
        name: 'Tampines',
        query: { where: 'Tampines East Region' },
      },
      {
        name: 'Sengkang',
        query: { where: 'Sengkang North-East Region' },
      },
      {
        name: 'Toa Payoh',
        query: { where: 'Toa Payoh Central Region' },
      },
      {
        name: 'Punggol',
        query: { where: 'Punggol North-East Region' },
      },
      {
        name: 'Bukit Batok',
        query: { where: 'Bukit Batok West Region' },
      },
      {
        name: 'Hougang',
        query: { where: 'Hougang North-East Region' },
      },
    ],
  },
};
