
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgTm93IiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgTm93IiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiLCJHbyB0byBDb21tdW5pdHkiOiJHbyB0byBDb21tdW5pdHkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiUGVtaWxpaGFuIHBla2VyamFhbiB5YW5nIGxlYmloIGJhaWsgZGFyaXBhZGEgc2ViZWx1bW55YSIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiBrYXJpciBkYW4gZ2FqaSBsZWJpaCBiYWlrIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiRGFwZXRpbiB0aXBzICYgZml0dXJueWEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIFBlbmNvY29rYW4ga2FuZGlkYXQgbGViaWggYmFpayIsIkhpcmUgZm9yIEZSRUUiOiJNdWxhaSBzZWthcmFuZywgR1JBVElTISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IlVwZGF0ZSBzZXB1dGFyIGluZm9ybWFzaSBnYWppIGRpIGluZHVzdHJpIGthbXUiLCJHbyB0byBDb21tdW5pdHkiOiJHYWJ1bmcgc2VrYXJhbmcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiUGVtaWxpaGFuIHBla2VyamFhbiB5YW5nIGxlYmloIGJhaWsgZGFyaXBhZGEgc2ViZWx1bW55YSIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiBrYXJpciBkYW4gZ2FqaSBsZWJpaCBiYWlrIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiRGFwZXRpbiB0aXBzICYgZml0dXJueWEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIFBlbmNvY29rYW4ga2FuZGlkYXQgbGViaWggYmFpayIsIkhpcmUgZm9yIEZSRUUiOiJNdWxhaSBzZWthcmFuZywgR1JBVElTISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IlVwZGF0ZSBzZXB1dGFyIGluZm9ybWFzaSBnYWppIGRpIGluZHVzdHJpIGthbXUiLCJHbyB0byBDb21tdW5pdHkiOiJHYWJ1bmcgc2VrYXJhbmcifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoi4Lie4Lia4LiH4Liy4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiXCJIZWxsb1wiIOC4nuC4muC4geC4seC4muC4h%2BC4suC4meC5geC4peC4sOC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4l%2BC4teC5iOC4lOC4teC4geC4p%2BC5iOC4siIsIkdldCB0aXBzIGFuZCB0b29scyI6IuC4leC4seC4p%2BC4iuC5iOC4p%2BC4ouC5g%2BC4meC4geC4suC4o%2BC4q%2BC4suC4h%2BC4suC4mSDguITguKXguLTguIEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIOC4hOC5ieC4meC4q%2BC4suC4hOC4meC4l%2BC4teC5iOC5g%2BC4iuC5iOC5hOC4lOC5ieC4lOC4teC4geC4p%2BC5iOC4suC4l%2BC4teC5iOC5gOC4hOC4oiIsIkhpcmUgZm9yIEZSRUUiOiLguKvguLLguITguJkg4Lif4Lij4Li1ISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSIsIkdvIHRvIENvbW11bml0eSI6IkdvIHRvIENvbW11bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoi4Lie4Lia4LiH4Liy4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiXCJIZWxsb1wiIOC4nuC4muC4geC4seC4muC4h%2BC4suC4meC5geC4peC4sOC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4l%2BC4teC5iOC4lOC4teC4geC4p%2BC5iOC4siIsIkdldCB0aXBzIGFuZCB0b29scyI6IuC4leC4seC4p%2BC4iuC5iOC4p%2BC4ouC5g%2BC4meC4geC4suC4o%2BC4q%2BC4suC4h%2BC4suC4mSDguITguKXguLTguIEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIOC4hOC5ieC4meC4q%2BC4suC4hOC4meC4l%2BC4teC5iOC5g%2BC4iuC5iOC5hOC4lOC5ieC4lOC4teC4geC4p%2BC5iOC4suC4l%2BC4teC5iOC5gOC4hOC4oiIsIkhpcmUgZm9yIEZSRUUiOiLguKvguLLguITguJkg4Lif4Lij4Li1ISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSIsIkdvIHRvIENvbW11bml0eSI6IkdvIHRvIENvbW11bml0eSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiUGVtaWxpaGFuIHBla2VyamFhbiB5YW5nIGxlYmloIGJhaWsgZGFyaXBhZGEgc2ViZWx1bW55YSIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiBrYXJpciBkYW4gZ2FqaSBsZWJpaCBiYWlrIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiRGFwZXRpbiB0aXBzICYgZml0dXJueWEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIFBlbmNvY29rYW4ga2FuZGlkYXQgbGViaWggYmFpayIsIkhpcmUgZm9yIEZSRUUiOiJNdWxhaSBzZWthcmFuZywgR1JBVElTISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IlVwZGF0ZSBzZXB1dGFyIGluZm9ybWFzaSBnYWppIGRpIGluZHVzdHJpIGthbXUiLCJHbyB0byBDb21tdW5pdHkiOiJHYWJ1bmcgc2VrYXJhbmcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiUGVtaWxpaGFuIHBla2VyamFhbiB5YW5nIGxlYmloIGJhaWsgZGFyaXBhZGEgc2ViZWx1bW55YSIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiBrYXJpciBkYW4gZ2FqaSBsZWJpaCBiYWlrIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiRGFwZXRpbiB0aXBzICYgZml0dXJueWEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIFBlbmNvY29rYW4ga2FuZGlkYXQgbGViaWggYmFpayIsIkhpcmUgZm9yIEZSRUUiOiJNdWxhaSBzZWthcmFuZywgR1JBVElTISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IlVwZGF0ZSBzZXB1dGFyIGluZm9ybWFzaSBnYWppIGRpIGluZHVzdHJpIGthbXUiLCJHbyB0byBDb21tdW5pdHkiOiJHYWJ1bmcgc2VrYXJhbmcifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoi4Lie4Lia4LiH4Liy4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiXCJIZWxsb1wiIOC4nuC4muC4geC4seC4muC4h%2BC4suC4meC5geC4peC4sOC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4l%2BC4teC5iOC4lOC4teC4geC4p%2BC5iOC4siIsIkdldCB0aXBzIGFuZCB0b29scyI6IuC4leC4seC4p%2BC4iuC5iOC4p%2BC4ouC5g%2BC4meC4geC4suC4o%2BC4q%2BC4suC4h%2BC4suC4mSDguITguKXguLTguIEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIOC4hOC5ieC4meC4q%2BC4suC4hOC4meC4l%2BC4teC5iOC5g%2BC4iuC5iOC5hOC4lOC5ieC4lOC4teC4geC4p%2BC5iOC4suC4l%2BC4teC5iOC5gOC4hOC4oiIsIkhpcmUgZm9yIEZSRUUiOiLguKvguLLguITguJkg4Lif4Lij4Li1ISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSIsIkdvIHRvIENvbW11bml0eSI6IkdvIHRvIENvbW11bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoi4Lie4Lia4LiH4Liy4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiXCJIZWxsb1wiIOC4nuC4muC4geC4seC4muC4h%2BC4suC4meC5geC4peC4sOC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4l%2BC4teC5iOC4lOC4teC4geC4p%2BC5iOC4siIsIkdldCB0aXBzIGFuZCB0b29scyI6IuC4leC4seC4p%2BC4iuC5iOC4p%2BC4ouC5g%2BC4meC4geC4suC4o%2BC4q%2BC4suC4h%2BC4suC4mSDguITguKXguLTguIEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIOC4hOC5ieC4meC4q%2BC4suC4hOC4meC4l%2BC4teC5iOC5g%2BC4iuC5iOC5hOC4lOC5ieC4lOC4teC4geC4p%2BC5iOC4suC4l%2BC4teC5iOC5gOC4hOC4oiIsIkhpcmUgZm9yIEZSRUUiOiLguKvguLLguITguJkg4Lif4Lij4Li1ISIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSIsIkdvIHRvIENvbW11bml0eSI6IkdvIHRvIENvbW11bml0eSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiW%2BG6nuG6veG6veG6veG5r%2BG5r%2BG6veG6veG6vcWZIMS1w7bDtsO2w58gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3FoS5dIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiW1wi4bio4bq94bq94bq9xprGmsO2w7bDtlwiIOG5r8O2w7bDtiDEg8SDxIMgw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxIPEg8SD4LiB4Li14LmJxowgxaHEg8SDxIPGmsSDxIPEg8WZw73DvcO9XSIsIkdldCB0aXBzIGFuZCB0b29scyI6IlvHpuG6veG6veG6veG5ryDhua%2FDrMOsw6zGpcWhIMSDxIPEg%2BC4geC4teC5icaMIOG5r8O2w7bDtsO2w7bDtsaaxaFdIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IltcIuG4qOG6veG6veG6vcaaxprDtsO2w7ZcIiDhua%2FDtsO2w7Ygw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDhua%2FEg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIkhpcmUgZm9yIEZSRUUiOiJb4biow6zDrMOsxZnhur3hur3hur0gxpLDtsO2w7bFmSDGkcWYw4vDi10iLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJb4bqsxprGmiDhua%2FhuKnhur3hur3hur0gxLXHmseax5rDrMOsw6zDp8O9w73DvSDFocSDxIPEg8aaxIPEg8SDxZnDvcO9w70g4LiB4Li14LmJ4bq94bq94bq9xbXFoSDDrMOsw6zguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgw6zDrMOs4LiB4Li14LmJxozHmseax5rFoeG5r8WZw73DvcO9XSIsIkdvIHRvIENvbW11bml0eSI6IlvHpsO2w7Yg4bmvw7bDtiDDh8O2w7ZtzIJtzILHmsea4LiB4Li14LmJw6zDrOG5r8O9w71dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiW%2BG6nuG6veG6veG6veG5r%2BG5r%2BG6veG6veG6vcWZIMS1w7bDtsO2w58gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3FoS5dIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiW1wi4bio4bq94bq94bq9xprGmsO2w7bDtlwiIOG5r8O2w7bDtiDEg8SDxIMgw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxIPEg8SD4LiB4Li14LmJxowgxaHEg8SDxIPGmsSDxIPEg8WZw73DvcO9XSIsIkdldCB0aXBzIGFuZCB0b29scyI6IlvHpuG6veG6veG6veG5ryDhua%2FDrMOsw6zGpcWhIMSDxIPEg%2BC4geC4teC5icaMIOG5r8O2w7bDtsO2w7bDtsaaxaFdIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IltcIuG4qOG6veG6veG6vcaaxprDtsO2w7ZcIiDhua%2FDtsO2w7Ygw5%2Fhur3hur3hur3hua%2Fhua%2Fhur3hur3hur3FmSDhua%2FEg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIkhpcmUgZm9yIEZSRUUiOiJb4biow6zDrMOsxZnhur3hur3hur0gxpLDtsO2w7bFmSDGkcWYw4vDi10iLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJb4bqsxprGmiDhua%2FhuKnhur3hur3hur0gxLXHmseax5rDrMOsw6zDp8O9w73DvSDFocSDxIPEg8aaxIPEg8SDxZnDvcO9w70g4LiB4Li14LmJ4bq94bq94bq9xbXFoSDDrMOsw6zguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgw6zDrMOs4LiB4Li14LmJxozHmseax5rFoeG5r8WZw73DvcO9XSIsIkdvIHRvIENvbW11bml0eSI6IlvHpsO2w7Yg4bmvw7bDtiDDh8O2w7ZtzIJtzILHmsea4LiB4Li14LmJw6zDrOG5r8O9w71dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    