
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiBUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4gQ29iYSBsYWdpIG5hbnRpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4gQ29iYSBsYWdpIG5hbnRpLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IuC4oeC4teC4guC5ieC4reC4nOC4tOC4lOC4nuC4peC4suC4lOC4l%2BC4suC4h%2BC4lOC5ieC4suC4meC4hOC4uOC4kyDguKXguK3guIfguYPguKvguKHguYjguYPguJnguK3guLXguIHguKrguLHguIHguITguKPguLnguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IuC4oeC4teC4guC5ieC4reC4nOC4tOC4lOC4nuC4peC4suC4lOC4l%2BC4suC4h%2BC4lOC5ieC4suC4meC4hOC4uOC4kyDguKXguK3guIfguYPguKvguKHguYjguYPguJnguK3guLXguIHguKrguLHguIHguITguKPguLnguYgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4gQ29iYSBsYWdpIG5hbnRpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4gQ29iYSBsYWdpIG5hbnRpLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IuC4oeC4teC4guC5ieC4reC4nOC4tOC4lOC4nuC4peC4suC4lOC4l%2BC4suC4h%2BC4lOC5ieC4suC4meC4hOC4uOC4kyDguKXguK3guIfguYPguKvguKHguYjguYPguJnguK3guLXguIHguKrguLHguIHguITguKPguLnguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IuC4oeC4teC4guC5ieC4reC4nOC4tOC4lOC4nuC4peC4suC4lOC4l%2BC4suC4h%2BC4lOC5ieC4suC4meC4hOC4uOC4kyDguKXguK3guIfguYPguKvguKHguYjguYPguJnguK3guLXguIHguKrguLHguIHguITguKPguLnguYgifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6Ilvhua7huKnhur3hur3hur3FmeG6veG6veG6vSDDrMOsw6zFoSDFocO2w7bDtm3MguG6veG6veG6veG5r%2BG4qcOsw6zDrOC4geC4teC5icSjIMW1xZnDtsO2w7bguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgw7bDtsO2x5rHmseaxZkg4bq94bq94bq94LiB4Li14LmJxowuIOG5rsWZw73DvcO9IMSDxIPEg8SjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDEg8SDxIMgxprDrMOsw6zhua%2Fhua%2FGmuG6veG6veG6vSDFteG4qcOsw6zDrMaa4bq94bq94bq9Ll0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6Ilvhua7huKnhur3hur3hur3FmeG6veG6veG6vSDDrMOsw6zFoSDFocO2w7bDtm3MguG6veG6veG6veG5r%2BG4qcOsw6zDrOC4geC4teC5icSjIMW1xZnDtsO2w7bguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgw7bDtsO2x5rHmseaxZkg4bq94bq94bq94LiB4Li14LmJxowuIOG5rsWZw73DvcO9IMSDxIPEg8SjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDEg8SDxIMgxprDrMOsw6zhua%2Fhua%2FGmuG6veG6veG6vSDFteG4qcOsw6zDrMaa4bq94bq94bq9Ll0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    