import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import {
  CreateReportedJobDocument,
  type ReportJobInput,
} from 'src/graphql/graphql';
import { logger } from 'src/modules/logger';
import {
  JOB_FRAUD_REPORT_SUCCESS,
  JOB_FRAUD_REPORT_FAILURE,
} from 'src/store/jobdetails/types';

const useCreateEvent = () => {
  const actionCreator = (success: boolean) => {
    const [type] = success
      ? [JOB_FRAUD_REPORT_SUCCESS]
      : [JOB_FRAUD_REPORT_FAILURE];

    return {
      type,
    };
  };
  return actionCreator;
};

export const useCreateReportedJob = () => {
  const dispatch = useDispatch();
  const actionCreator = useCreateEvent();

  const [reportJob] = useMutation(CreateReportedJobDocument);

  return async (report: ReportJobInput) => {
    try {
      const result = await reportJob({ variables: { report } });
      const success = result?.data?.reportJob?.success || false;
      dispatch(actionCreator(success));
      return success;
    } catch (error) {
      logger.error(error as Error, 'Error calling reportJobAd');
      dispatch(actionCreator(false));
      return false;
    }
  };
};
