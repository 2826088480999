
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJ5b3VyIHNhdmVkIGpvYnMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoieW91ciBzYXZlZCBzZWFyY2hlcyIsIlZpZXcgYWxsICh7Y291bnR9KSI6IlZpZXcgYWxsICh7Y291bnR9KSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJMb3dvbmdhbiBrZXJqYSB5YW5nIGthbXUgc2ltcGFuIiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IlBlbmNhcmlhbiB5YW5nIGthbXUgc2ltcGFuIiwiVmlldyBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJMb3dvbmdhbiBrZXJqYSB5YW5nIGthbXUgc2ltcGFuIiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IlBlbmNhcmlhbiB5YW5nIGthbXUgc2ltcGFuIiwiVmlldyBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiLguIfguLLguJnguJfguLXguYjguJrguLHguJnguJfguLbguIHguYTguKfguYnguILguK3guIfguITguLjguJMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoi4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJ4LiC4Lit4LiH4LiE4Li44LiTIiwiVmlldyBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiLguIfguLLguJnguJfguLXguYjguJrguLHguJnguJfguLbguIHguYTguKfguYnguILguK3guIfguITguLjguJMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoi4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJ4LiC4Lit4LiH4LiE4Li44LiTIiwiVmlldyBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJMb3dvbmdhbiBrZXJqYSB5YW5nIGthbXUgc2ltcGFuIiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IlBlbmNhcmlhbiB5YW5nIGthbXUgc2ltcGFuIiwiVmlldyBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJMb3dvbmdhbiBrZXJqYSB5YW5nIGthbXUgc2ltcGFuIiwieW91ciBzYXZlZCBzZWFyY2hlcyI6IlBlbmNhcmlhbiB5YW5nIGthbXUgc2ltcGFuIiwiVmlldyBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiLguIfguLLguJnguJfguLXguYjguJrguLHguJnguJfguLbguIHguYTguKfguYnguILguK3guIfguITguLjguJMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoi4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJ4LiC4Lit4LiH4LiE4Li44LiTIiwiVmlldyBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiLguIfguLLguJnguJfguLXguYjguJrguLHguJnguJfguLbguIHguYTguKfguYnguILguK3guIfguITguLjguJMiLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoi4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJ4LiC4Lit4LiH4LiE4Li44LiTIiwiVmlldyBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJbw73DvcO2w7bHmseaxZkgxaHEg8SD4bm94bq94bq9xowgxLXDtsO2w5%2FFoV0iLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoiW8O9w73DtsO2x5rHmsWZIMWhxIPEg%2BG5veG6veG6vcaMIMWh4bq94bq9xIPEg8WZw6fhuKnhur3hur3FoV0iLCJWaWV3IGFsbCAoe2NvdW50fSkiOiJb4bm8w6zDrMOs4bq94bq94bq9xbUgxIPEg8SDxprGmiAoe2NvdW50fSldIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ5b3VyIHNhdmVkIGpvYnMiOiJbw73DvcO2w7bHmseaxZkgxaHEg8SD4bm94bq94bq9xowgxLXDtsO2w5%2FFoV0iLCJ5b3VyIHNhdmVkIHNlYXJjaGVzIjoiW8O9w73DtsO2x5rHmsWZIMWhxIPEg%2BG5veG6veG6vcaMIMWh4bq94bq9xIPEg8WZw6fhuKnhur3hur3FoV0iLCJWaWV3IGFsbCAoe2NvdW50fSkiOiJb4bm8w6zDrMOs4bq94bq94bq9xbUgxIPEg8SDxprGmiAoe2NvdW50fSldIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    