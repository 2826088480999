import type { Language } from '@seek/melways-sites';

import type { QuickSearchLinkProps } from '../components/QuickSearchLink';

interface OtherLink {
  keywords: string;
  location: QuickSearchLinkProps['linkProps']['location'];
  rel?: string;
}

type OtherLinks = {
  [key in Language]?: OtherLink[];
};

export const otherLinks: Record<string, OtherLinks> = {
  'anz-1': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      {
        keywords: 'Work From Home Jobs',
        location: { pathname: '/work-from-home-jobs' },
      },
      {
        keywords: 'FIFO Jobs',
        location: { pathname: '/fifo-jobs' },
      },
      { keywords: 'Ethical Jobs', location: { pathname: '/ethical-jobs' } },
      {
        keywords: 'Government Jobs',
        location: { pathname: 'government-jobs' },
      },
      { keywords: 'Web3 Jobs', location: { pathname: '/web3-jobs' } },
      {
        keywords: 'Salary Calculator',
        location: { pathname: '/career-advice/page/salary-calculator' },
      },
    ],
  },
  'anz-2': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      {
        keywords: 'No Experience Jobs',
        location: { pathname: '/no-experience-jobs' },
      },
      {
        keywords: 'Work From Home Jobs',
        location: { pathname: '/work-from-home-jobs' },
      },
      {
        keywords: 'FIFO Jobs',
        location: { pathname: '/fifo-jobs' },
      },
      { keywords: 'Farm Jobs', location: { pathname: '/farm-jobs' } },
      {
        keywords: 'Government Jobs',
        location: { pathname: '/government-jobs' },
      },
      {
        keywords: 'Visa Sponsorship Jobs',
        location: { pathname: '/visa-sponsorship-jobs' },
      },
    ],
  },
  'asia-1': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      {
        keywords: 'Government Jobs',
        location: { pathname: '/safety-officer-jobs' },
      },
      { keywords: 'Clerk Jobs', location: { pathname: '/clerk-jobs' } },
      {
        keywords: 'Math Tutor Jobs',
        location: { pathname: '/math-tutor-jobs' },
      },
      {
        keywords: '物理治療助理 Jobs',
        location: {
          pathname:
            '/%E7%89%A9%E7%90%86%E6%B2%BB%E7%99%82%E5%8A%A9%E7%90%86-jobs',
        },
      },
    ],
  },
  'asia-3': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      {
        keywords: 'Work From Home Jobs',
        location: { pathname: '/work-from-home-jobs' },
      },
      {
        keywords: 'Safety Officer Jobs',
        location: { pathname: '/safety-officer-jobs' },
      },
      { keywords: 'Admin Jobs', location: { pathname: '/admin-jobs' } },
      { keywords: 'Lawyer Jobs', location: { pathname: '/lawyer-jobs' } },
    ],
    th: [
      { keywords: 'งานทั้งหมด', location: { pathname: '/jobs' } },
      {
        keywords: 'งาน Work From Home',
        location: { pathname: '/work-from-home-jobs' },
      },
      {
        keywords: 'งาน Safety Officer',
        location: { pathname: '/safety-officer-jobs' },
      },
      { keywords: 'งาน Admin', location: { pathname: '/admin-jobs' } },
      {
        keywords: 'เครื่องคิดเลขเงินเดือน',
        location: { pathname: '/lawyer-jobs' },
      },
    ],
  },
  'asia-4': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      { keywords: 'Online Jobs', location: { pathname: '/online-jobs' } },
      { keywords: 'Freelance Jobs', location: { pathname: '/Freelance-jobs' } },
      { keywords: 'Driver Jobs', location: { pathname: '/driver-jobs' } },
      { keywords: 'Admin Jobs', location: { pathname: '/admin-jobs' } },
    ],
    id: [
      { keywords: 'Semua Lowongan Kerja', location: { pathname: '/jobs' } },
      { keywords: 'Kerja Online', location: { pathname: '/online-jobs' } },
      {
        keywords: 'Kerja Freelance',
        location: { pathname: '/Freelance-jobs' },
      },
      { keywords: 'Kerja Tambang', location: { pathname: '/lambang-jobs' } },
      {
        keywords: 'Lowongan Lulusan Sma',
        location: { pathname: '/lulusan-sma-jobs' },
      },
    ],
  },
  'asia-5': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      { keywords: 'Admin Jobs', location: { pathname: '/admin-jobs' } },
      {
        keywords: 'Government Jobs',
        location: { pathname: '/government-jobs' },
      },
      {
        keywords: 'Technician Jobs',
        location: { pathname: '/technician-jobs' },
      },
    ],
  },
  'asia-6': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      {
        keywords: 'Work From Home Jobs',
        location: { pathname: '/work-from-home-jobs' },
      },
      {
        keywords: 'Virtual Assistant Jobs',
        location: { pathname: '/virtual-assistant-jobs' },
      },
      {
        keywords: 'Urgent Hiring Jobs',
        location: { pathname: '/urgent-hiring-jobs' },
      },
      {
        keywords: 'Government Jobs',
        location: { pathname: '/government-jobs' },
      },
      {
        keywords: 'Work Abroad Jobs',
        location: { pathname: '/work-abroad-jobs' },
      },
      {
        keywords: 'POEA Jobs',
        location: { pathname: '/poea-jobs' },
      },
    ],
  },
  'asia-7': {
    en: [
      { keywords: 'All Jobs', location: { pathname: '/jobs' } },
      {
        keywords: 'Work From Home Jobs',
        location: { pathname: '/work-from-home-jobs' },
      },
      { keywords: 'Freelance Jobs', location: { pathname: '/Freelance-jobs' } },
      { keywords: 'Intern Jobs', location: { pathname: '/intern-jobs' } },
      {
        keywords: 'Government Jobs',
        location: { pathname: '/government-jobs' },
      },
    ],
  },
};
