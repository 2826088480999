import { useTranslations } from '@vocab/react';
import {
  Bleed,
  Box,
  Heading,
  Hidden,
  IconChevron,
  Inline,
  PageBlock,
} from 'braid-design-system';
import { useEffect, useState } from 'react';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import { useIsHomepageMobileWebRedesignEnabled } from 'src/store/featureFlags/hooks';
import { isMobileWebRedesignFeatureFlagReady } from 'src/store/featureFlags/selectors';
import { useSelector } from 'src/store/react';
import { selectAuthenticated, selectRecentSearches } from 'src/store/selectors';

import translations from './.vocab';
import HorizontalScrollContainer from './components/HorizontalScrollContainer';
import RecentSearchItem from './components/RecentSearchItem';

import * as styles from './RecentSearchesBar.css';

const ITEM_GAP = 'xsmall';
const HORIZONTAL_SCROLLING_PADDING = 'xxsmall';

const RecentSearchesBar = () => {
  const analyticsFacade = useAnalyticsFacade();

  const [isExpanded, setIsExpanded] = useState(false);
  const isHomepageMobileWebRedesignEnabled =
    useIsHomepageMobileWebRedesignEnabled();
  const searchItems = useSelector(selectRecentSearches);
  const isMobileOrTablet = isMobileUserAgent();
  const isMobileWebExperimentReady = useSelector(
    isMobileWebRedesignFeatureFlagReady,
  );
  const isAuthenticated = useSelector(selectAuthenticated);

  const { t } = useTranslations(translations);

  useEffect(() => {
    analyticsFacade.recentSearchesImpression({
      cardDisplayedCount: `${searchItems?.length ?? 0}`,
      recentSearchesCollapsed: isMobileOrTablet,
      isExitLink: false,
    });
  }, [analyticsFacade, isMobileOrTablet, searchItems]);

  if (!searchItems?.length) {
    return null;
  }

  const content = searchItems.map((item, index) =>
    item ? (
      <Box key={index} data-automation={`recent-search-item-${index}`}>
        <RecentSearchItem
          search={item}
          listIndex={index + 1}
          listDisplayCount={searchItems.length}
        />
      </Box>
    ) : null,
  );

  /**
   * Remove the following code after the mobile web experiment.
   * This is to ensure that we are showing a loading state while
   * we are waiting for the feature flag to be ready.
   */
  if (isAuthenticated && !isMobileWebExperimentReady) {
    return (
      <PageBlock data={{ automation: 'recent-searches-bar' }}>
        <Hidden below="desktop">
          <Box marginTop="gutter">
            <Inline space={ITEM_GAP}>{content}</Inline>
          </Box>
        </Hidden>
        <Hidden above="desktop">
          <Box marginTop="medium">
            <SkeletonLine width={240} height={40} type="text" />
          </Box>
        </Hidden>
      </PageBlock>
    );
  }

  if (isHomepageMobileWebRedesignEnabled) {
    return (
      <PageBlock data={{ automation: 'recent-searches-bar' }}>
        <Bleed bottom={HORIZONTAL_SCROLLING_PADDING}>
          <HorizontalScrollContainer>
            <Box
              marginTop={{ mobile: 'medium', desktop: 'gutter' }}
              className={styles.multilineItems}
              display="flex"
              paddingBottom={HORIZONTAL_SCROLLING_PADDING}
              gap={ITEM_GAP}
            >
              {content}
            </Box>
          </HorizontalScrollContainer>
        </Bleed>
      </PageBlock>
    );
  }
  return (
    <PageBlock data={{ automation: 'recent-searches-bar' }}>
      <Box
        component="button"
        textAlign="left"
        width="full"
        cursor="pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        marginTop={{ mobile: 'xlarge' }}
        display={{
          tablet: searchItems.length >= 3 ? 'block' : 'none',
          desktop: 'none',
        }}
      >
        <Heading level="3">
          <Box
            component="span"
            display="flex"
            justifyContent="spaceBetween"
            alignItems="center"
          >
            {t('Recent searches')}{' '}
            <IconChevron direction={isExpanded ? 'up' : 'down'} />
          </Box>
        </Heading>
      </Box>
      <Box
        display={{
          mobile: isExpanded ? 'block' : 'none',
          desktop: 'block',
        }}
        paddingTop={{
          mobile: isExpanded ? 'medium' : 'none',
          desktop: 'gutter',
        }}
      >
        <Inline space={ITEM_GAP}>{content}</Inline>
      </Box>
    </PageBlock>
  );
};

export default RecentSearchesBar;
