const BriefcaseWithStarSvg = () => (
  <svg width="96" height="97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y=".5" width="96" height="96" rx="48" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.1 30a2 2 0 0 0-2 2.1V40c0 1.1.9 2 2 2h13c1.2 0 2.1-.9 2.1-2V32c0-1.1-.9-2-2-2h-13Zm-6.5 2.1c0-3.6 3-6.5 6.5-6.5h13c3.7 0 6.6 3 6.6 6.5V40c0 3.6-3 6.5-6.5 6.5h-13c-3.7 0-6.6-3-6.6-6.5V32Z"
      fill="#0D3880"
    />
    <path
      d="M68.4 48.2H26.9c-1.9 0-3.4 1.6-3.4 3.5v14.5c0 2 1.5 3.5 3.4 3.5h41.5c1.9 0 3.4-1.5 3.4-3.5V51.7c0-2-1.5-3.5-3.4-3.5Z"
      fill="#F470BD"
    />
    <path
      d="M70 51.5 48 54h-.7l-22-2.5c-1.8-.2-3.1-1.7-3.1-3.5V38c0-1.8 1.5-3.4 3.5-3.4h44c1.9 0 3.4 1.6 3.4 3.5v10c0 1.7-1.3 3.2-3 3.4Z"
      fill="#EE399E"
    />
    <path
      d="M51.7 51h-8.1c-.4 0-.7.2-.7.6v4c0 .4.3.7.7.7h8.1c.4 0 .7-.3.7-.7v-4c0-.4-.3-.7-.7-.7Z"
      fill="#F7F8FB"
    />
    <path
      d="m74.4 23 2.3 4.8 5.1.7c1.1.2 1.6 1.6.8 2.4l-3.7 3.7.9 5.1c.2 1.2-1 2-2 1.5l-4.6-2.4-4.6 2.4c-1 .6-2.2-.3-2-1.5l.8-5.1-3.6-3.7c-.9-.8-.4-2.2.7-2.4l5.1-.7L72 23c.5-1 2-1 2.5 0Z"
      fill="#FDC221"
    />
  </svg>
);

export default BriefcaseWithStarSvg;
