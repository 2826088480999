
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiU2lnbiBpbiBvciByZWdpc3RlciIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIiwiU2lnbiBpbiI6IlNpZ24gaW4iLCJSZWdpc3RlciI6IlJlZ2lzdGVyIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiTWFzdWsgYXRhdSBkYWZ0YXIiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoiTWFzdWsgdW50dWsgbWVuZ2Vsb2xhIFByb2ZpbCB7YnJhbmR9LCBtZW55aW1wYW4gcGVuY2FyaWFuLCBkYW4gbWVsaWhhdCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpc2FyYW5rYW4uIiwiU2lnbiBpbiI6Ik1hc3VrIiwiUmVnaXN0ZXIiOiJEYWZ0YXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiTWFzdWsgYXRhdSBkYWZ0YXIiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoiTWFzdWsgdW50dWsgbWVuZ2Vsb2xhIFByb2ZpbCB7YnJhbmR9LCBtZW55aW1wYW4gcGVuY2FyaWFuLCBkYW4gbWVsaWhhdCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpc2FyYW5rYW4uIiwiU2lnbiBpbiI6Ik1hc3VrIiwiUmVnaXN0ZXIiOiJEYWZ0YXIifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIOC4q%2BC4o%2BC4t%2BC4rSDguKXguIfguJfguLDguYDguJrguLXguKLguJkiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4Lia4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiI4Lix4LiU4LiB4Liy4Lij4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiTIOC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC5geC4peC4sOC4lOC4ueC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4syIsIlNpZ24gaW4iOiLguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJSZWdpc3RlciI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIOC4q%2BC4o%2BC4t%2BC4rSDguKXguIfguJfguLDguYDguJrguLXguKLguJkiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4Lia4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiI4Lix4LiU4LiB4Liy4Lij4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiTIOC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC5geC4peC4sOC4lOC4ueC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4syIsIlNpZ24gaW4iOiLguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJSZWdpc3RlciI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiTWFzdWsgYXRhdSBkYWZ0YXIiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoiTWFzdWsgdW50dWsgbWVuZ2Vsb2xhIFByb2ZpbCB7YnJhbmR9LCBtZW55aW1wYW4gcGVuY2FyaWFuLCBkYW4gbWVsaWhhdCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpc2FyYW5rYW4uIiwiU2lnbiBpbiI6Ik1hc3VrIiwiUmVnaXN0ZXIiOiJEYWZ0YXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiTWFzdWsgYXRhdSBkYWZ0YXIiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoiTWFzdWsgdW50dWsgbWVuZ2Vsb2xhIFByb2ZpbCB7YnJhbmR9LCBtZW55aW1wYW4gcGVuY2FyaWFuLCBkYW4gbWVsaWhhdCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpc2FyYW5rYW4uIiwiU2lnbiBpbiI6Ik1hc3VrIiwiUmVnaXN0ZXIiOiJEYWZ0YXIifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIOC4q%2BC4o%2BC4t%2BC4rSDguKXguIfguJfguLDguYDguJrguLXguKLguJkiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4Lia4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiI4Lix4LiU4LiB4Liy4Lij4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiTIOC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC5geC4peC4sOC4lOC4ueC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4syIsIlNpZ24gaW4iOiLguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJSZWdpc3RlciI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIOC4q%2BC4o%2BC4t%2BC4rSDguKXguIfguJfguLDguYDguJrguLXguKLguJkiLCJTaWduIGluIHRvIG1hbmFnZSB5b3VyIHticmFuZH0gUHJvZmlsZSwgc2F2ZSBzZWFyY2hlcyBhbmQgdmlldyB5b3VyIHJlY29tbWVuZGVkIGpvYnMuIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4Lia4LmD4LiK4LmJ4LmA4Lie4Li34LmI4Lit4LiI4Lix4LiU4LiB4Liy4Lij4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiTIOC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC5geC4peC4sOC4lOC4ueC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4syIsIlNpZ24gaW4iOiLguYDguILguYnguLLguKrguLnguYjguKPguLDguJrguJoiLCJSZWdpc3RlciI6IuC4peC4h%2BC4l%2BC4sOC5gOC4muC4teC4ouC4mSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIMO2w7bDtsWZIMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r%2BG6veG6veG6vcWZXSIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJbxaDDrMOsw6zEo%2BC4geC4teC5iSDDrMOsw6zguIHguLXguYkg4bmvw7bDtsO2IG3MgsSDxIPEg%2BC4geC4teC5icSDxIPEg8Sj4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIHticmFuZH0gxqTFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0sIMWhxIPEg8SD4bm94bq94bq94bq9IMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qeG6veG6veG6vcWhIMSDxIPEg%2BC4geC4teC5icaMIOG5vcOsw6zDrOG6veG6veG6vcW1IMO9w73DvcO2w7bDtseax5rHmsWZIMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjOG6veG6veG6vcaMIMS1w7bDtsO2w5%2FFoS5dIiwiU2lnbiBpbiI6IlvFoMOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iV0iLCJSZWdpc3RlciI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua%2Fhur3hur3hur3FmV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIG9yIHJlZ2lzdGVyIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIMO2w7bDtsWZIMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r%2BG6veG6veG6vcWZXSIsIlNpZ24gaW4gdG8gbWFuYWdlIHlvdXIge2JyYW5kfSBQcm9maWxlLCBzYXZlIHNlYXJjaGVzIGFuZCB2aWV3IHlvdXIgcmVjb21tZW5kZWQgam9icy4iOiJbxaDDrMOsw6zEo%2BC4geC4teC5iSDDrMOsw6zguIHguLXguYkg4bmvw7bDtsO2IG3MgsSDxIPEg%2BC4geC4teC5icSDxIPEg8Sj4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIHticmFuZH0gxqTFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0sIMWhxIPEg8SD4bm94bq94bq94bq9IMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qeG6veG6veG6vcWhIMSDxIPEg%2BC4geC4teC5icaMIOG5vcOsw6zDrOG6veG6veG6vcW1IMO9w73DvcO2w7bDtseax5rHmsWZIMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjOG6veG6veG6vcaMIMS1w7bDtsO2w5%2FFoS5dIiwiU2lnbiBpbiI6IlvFoMOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iV0iLCJSZWdpc3RlciI6IlvFmOG6veG6veG6vcSjw6zDrMOsxaHhua%2Fhur3hur3hur3FmV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    