import { useMemo } from 'react';

import { useSelector } from 'src/store/react';
import { createJobLinkSolReferenceSelector } from 'src/store/results';

const useSolMetadataReference = (
  props: Parameters<typeof createJobLinkSolReferenceSelector>[0],
) => {
  const selectSolMetadataReference = useMemo(
    () => createJobLinkSolReferenceSelector(props),
    [props],
  );

  const solMetadataReference = useSelector(selectSolMetadataReference);

  return solMetadataReference;
};

export default useSolMetadataReference;
