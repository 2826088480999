import { useQuery } from '@apollo/client';
import type { ComponentProps } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import {
  GetHomeSavedJobsDocument,
  type GetHomeSavedJobsQuery,
  type GetHomeSavedJobsQueryVariables,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import type { ApolloCacheType } from 'src/types/apollo';

import type JobCard from '../SharedComponent/JobCard/JobCard';

type SavedJobEdges = NonNullable<
  NonNullable<
    NonNullable<GetHomeSavedJobsQuery['viewer']>['savedJobs']
  >['edges']
>;
export type SavedJob = SavedJobEdges[number]['node']['job'];

const useHomeSavedJobGraphVariables = (): GetHomeSavedJobsQueryVariables => {
  const { locale, zone } = useAppConfig();
  const timezone = useTimezone();

  return {
    locale,
    timezone,
    zone,
  };
};

const mapSavedJobs = (
  edges?: SavedJobEdges,
): Array<ComponentProps<typeof JobCard>['jobInfo']> =>
  edges?.map((edge) => {
    const {
      isActive,
      job: { advertiser, id, title, listedAt, location, salary, products },
    } = edge.node;

    return {
      advertiserName: advertiser.name,
      id,
      locationLabel: location.label,
      salaryLabel: salary?.label,
      salaryCurrencyLabel: salary?.currencyLabel ?? undefined,
      title,
      listingDateLabel: listedAt?.label,
      srcLogo: products?.branding?.logo?.url,
      isExpired: !isActive,
    };
  }) || [];

export const useSavedJobs = () => {
  const variables = useHomeSavedJobGraphVariables();

  const { data, error, loading } = useQuery(GetHomeSavedJobsDocument, {
    variables,
    ssr: false,
  });

  return {
    jobs: mapSavedJobs(data?.viewer?.savedJobs.edges) ?? null,
    totalCount: data?.viewer?.savedJobs.total ?? 0,
    error,
    loading,
  };
};

const findSavedJobFromQuery = (
  existingSavedJobsQuery: GetHomeSavedJobsQuery,
  jobId: string,
) => {
  const savedJobs = existingSavedJobsQuery.viewer?.savedJobs.edges;
  return savedJobs?.find((savedJob) => savedJob.node.id === jobId)?.node;
};

export const useUpdateHomeSavedJobsCache = (job: SavedJob) => {
  const variables = useHomeSavedJobGraphVariables();

  const addSavedJob = (cache: ApolloCacheType) => {
    const existingSavedJobsQuery = cache.readQuery({
      query: GetHomeSavedJobsDocument,
      variables,
    });

    if (!existingSavedJobsQuery?.viewer) {
      return;
    }

    if (!findSavedJobFromQuery(existingSavedJobsQuery, job.id)) {
      const newSavedJobs: SavedJobEdges = [
        {
          __typename: 'SavedJobEdge' as const,
          node: {
            __typename: 'SavedJob' as const,
            id: job.id,
            isActive: true,
            job: {
              ...job,
              __typename: 'Job' as const,
            },
          },
        },
        ...existingSavedJobsQuery.viewer.savedJobs.edges,
      ].slice(0, 3);

      cache.writeQuery({
        query: GetHomeSavedJobsDocument,
        variables,
        data: {
          ...existingSavedJobsQuery,
          viewer: {
            ...existingSavedJobsQuery.viewer,
            savedJobs: {
              ...existingSavedJobsQuery.viewer.savedJobs,
              total: existingSavedJobsQuery.viewer.savedJobs.total + 1,
              edges: newSavedJobs,
            },
          },
        },
      });
    }
  };

  const removeSavedJob = (cache: ApolloCacheType) => {
    const existingSavedJobsQuery = cache.readQuery({
      query: GetHomeSavedJobsDocument,
      variables,
    });

    if (!existingSavedJobsQuery?.viewer) {
      return;
    }

    if (
      existingSavedJobsQuery.viewer.savedJobs.total > 3 &&
      findSavedJobFromQuery(existingSavedJobsQuery, job.id)
    ) {
      cache.modify({
        id: cache.identify(existingSavedJobsQuery.viewer),
        fields: {
          savedJobs(_, details) {
            // By marking this as delete - it forces the query to be re-fetched
            // in home page.
            return details.DELETE;
          },
        },
      });
    } else {
      const savedJobs = existingSavedJobsQuery.viewer.savedJobs.edges;

      cache.writeQuery({
        query: GetHomeSavedJobsDocument,
        variables,
        data: {
          ...existingSavedJobsQuery,
          viewer: {
            ...existingSavedJobsQuery.viewer,
            savedJobs: {
              ...existingSavedJobsQuery.viewer.savedJobs,
              edges: savedJobs.filter(
                (savedJob) => savedJob.node.id !== job.id,
              ),
              total: existingSavedJobsQuery.viewer.savedJobs.total - 1,
            },
          },
        },
      });
    }
  };

  return { addSavedJob, removeSavedJob };
};
