import { useTranslations } from '@vocab/react';
import { Stack, Text, PageBlock } from 'braid-design-system';
import { memo } from 'react';

import translations from './.vocab';
import Classifications from './components/Classifications';
import { MajorCities } from './components/MajorCities';
import { Other } from './components/Other';

const QuickSearch = () => {
  const { t } = useTranslations(translations);

  return (
    <PageBlock
      component="section"
      data={{ automation: 'quick-search-container' }}
    >
      <Stack space="medium">
        <Text size="standard" component="h1" weight="medium">
          {t('Quick search')}
        </Text>
        <Classifications />
        <MajorCities />
        <Other />
      </Stack>
    </PageBlock>
  );
};

export default memo(QuickSearch);
