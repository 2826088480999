import { Box, Heading, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

interface HeadingSkeletonType {
  type: 'heading';
  level: ComponentProps<typeof Heading>['level'];
  size?: never;
}

interface TextSkeletonType {
  type: 'text';
  size?: ComponentProps<typeof Text>['size'];
  level?: never;
}

interface ButtonSkeletonType {
  type: 'button';
  level?: never;
  size?: never;
}

type SkeletonLineProps = {
  width: number;
  height?: number;
} & (TextSkeletonType | HeadingSkeletonType | ButtonSkeletonType);

const SkeletonLine = function ({
  width,
  type,
  level,
  height,
  size,
}: SkeletonLineProps) {
  const content =
    type === 'heading' ? (
      <Heading component="span" level={level}>
        &nbsp;
      </Heading>
    ) : (
      (type === 'text' && <Text size={size}>&nbsp;</Text>) || null
    );

  return (
    <Box
      component="span"
      display="block"
      borderRadius="standard"
      background="neutralLight"
      height={type === 'button' ? 'touchable' : undefined}
      style={{ width, ...(height ? { height } : {}) }}
    >
      {content}
    </Box>
  );
};

export default SkeletonLine;
