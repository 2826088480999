import { useTranslations } from '@vocab/react';
import { Box, Inline, Text } from 'braid-design-system';

import { useAppConfig } from 'src/config/appConfig';

import translations from '../.vocab';
import { majorCitiesLinks } from '../utils/majorCities';

import { QuickSearchLink } from './QuickSearchLink';

import * as styles from '../styles.css';

export const MajorCities = () => {
  const { zone, language } = useAppConfig();
  const { t } = useTranslations(translations);
  const links = majorCitiesLinks[zone][language];

  return links && links.length > 0 ? (
    <Box display="flex">
      <Box className={styles.rowLeftColumn}>
        <Text size="small" component="h2">
          {t('Major cities')}
        </Text>
      </Box>
      <Inline space="small" component="ul">
        {links.map(({ name, query }) => (
          <QuickSearchLink
            key={`Jobs in ${name}`}
            linkProps={{
              ['aria-label']: t('Jobs in {name}', {
                name,
              }),
              ['data-automation']: 'quick-search-city-link',
              analytics: 'quick-search-city',
              location: {
                pathname: '/jobs',
                query,
              },
            }}
          >
            {name}
          </QuickSearchLink>
        ))}
      </Inline>
    </Box>
  ) : null;
};
