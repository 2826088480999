import { useCurrentPage } from '../useCurrentPage/useCurrentPage';

import { useSavedJobsData } from './utils/useSavedJobsData';
import {
  type UseToggleSaveJobProps,
  useToggleSavedJob,
} from './utils/useToggleSavedJob';

const noop = () => {};

export type UseSaveJobJDVProps = Pick<
  UseToggleSaveJobProps,
  'jobId' | 'jobTracking' | 'solMetadataReference' | 'savedJobMutation'
>;

export const useSaveJobJDV = ({
  jobId,
  jobTracking,
  solMetadataReference,
  savedJobMutation,
}: UseSaveJobJDVProps) => {
  const jobDetailsPersonalised = useSavedJobsData({ isJobDetailsView: true });
  const currentPage = useCurrentPage();

  const { toggleSavedJob, isApplied, isSaved } = useToggleSavedJob({
    jobId,
    view: 'jobDetails',
    linkPosition: 'job details',
    jobViewType: currentPage === 'job details' ? 'standalone' : undefined,
    solMetadataReference,
    jobTracking,
    savedJobsData: jobDetailsPersonalised?.data,
    savedJobMutation,
  });

  return {
    isApplied,
    isSaved,
    toggleSavedJob: isApplied ? noop : toggleSavedJob,
  };
};
