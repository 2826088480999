
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNFRUsgaXMgQXVzdHJhbGlh4oCZcyBudW1iZXIgb25lIGVtcGxveW1lbnQgbWFya2V0cGxhY2UuIEZpbmQgam9icyBhbmQgY2FyZWVyIHJlbGF0ZWQgaW5mb3JtYXRpb24gb3IgcmVjcnVpdCB0aGUgaWRlYWwgY2FuZGlkYXRlLiBXaHkgc2V0dGxlPyBTRUVLIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNFRUsgaXMgQXVzdHJhbGlh4oCZcyBudW1iZXIgb25lIGVtcGxveW1lbnQgbWFya2V0cGxhY2UuIEZpbmQgam9icyBhbmQgY2FyZWVyIHJlbGF0ZWQgaW5mb3JtYXRpb24gb3IgcmVjcnVpdCB0aGUgaWRlYWwgY2FuZGlkYXRlLiBXaHkgc2V0dGxlPyBTRUVLIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNFRUsgaXMgQXVzdHJhbGlh4oCZcyBudW1iZXIgb25lIGVtcGxveW1lbnQgbWFya2V0cGxhY2UuIEZpbmQgam9icyBhbmQgY2FyZWVyIHJlbGF0ZWQgaW5mb3JtYXRpb24gb3IgcmVjcnVpdCB0aGUgaWRlYWwgY2FuZGlkYXRlLiBXaHkgc2V0dGxlPyBTRUVLIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNFRUsgaXMgQXVzdHJhbGlh4oCZcyBudW1iZXIgb25lIGVtcGxveW1lbnQgbWFya2V0cGxhY2UuIEZpbmQgam9icyBhbmQgY2FyZWVyIHJlbGF0ZWQgaW5mb3JtYXRpb24gb3IgcmVjcnVpdCB0aGUgaWRlYWwgY2FuZGlkYXRlLiBXaHkgc2V0dGxlPyBTRUVLIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNFRUsgaXMgTmV3IFplYWxhbmQncyBudW1iZXIgb25lIGVtcGxveW1lbnQgbWFya2V0cGxhY2UuIEZpbmQgam9icyBhbmQgY2FyZWVyIHJlbGF0ZWQgaW5mb3JtYXRpb24gb3IgcmVjcnVpdCB0aGUgaWRlYWwgY2FuZGlkYXRlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNFRUsgaXMgTmV3IFplYWxhbmQncyBudW1iZXIgb25lIGVtcGxveW1lbnQgbWFya2V0cGxhY2UuIEZpbmQgam9icyBhbmQgY2FyZWVyIHJlbGF0ZWQgaW5mb3JtYXRpb24gb3IgcmVjcnVpdCB0aGUgaWRlYWwgY2FuZGlkYXRlLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCwgYnJvd3NlIGFuZCBhcHBseSB0aGUgbGF0ZXN0IEFkbWluICYgSFIsIEJhbmtpbmcsIElULCBTYWxlcywgTWFya2V0aW5nIGFuZCBtYW55IG90aGVyIGpvYnMgaW4gSG9uZyBLb25nLiBTdGFydCB5b3VyIGpvYiBzZWFyY2ggaW4gSG9uZyBLb25nIGF0IEpvYnNkYi5jb20uIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCwgYnJvd3NlIGFuZCBhcHBseSB0aGUgbGF0ZXN0IEFkbWluICYgSFIsIEJhbmtpbmcsIElULCBTYWxlcywgTWFya2V0aW5nIGFuZCBtYW55IG90aGVyIGpvYnMgaW4gSG9uZyBLb25nLiBTdGFydCB5b3VyIGpvYiBzZWFyY2ggaW4gSG9uZyBLb25nIGF0IEpvYnNkYi5jb20uIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCwgYnJvd3NlIGFuZCBhcHBseSB0aGUgbGF0ZXN0IEFkbWluICYgSFIsIEJhbmtpbmcsIElULCBTYWxlcywgTWFya2V0aW5nIGFuZCBtYW55IG90aGVyIGpvYnMgaW4gVGhhaWxhbmQuIFN0YXJ0IHlvdXIgam9iIHNlYXJjaCBpbiBUaGFpbGFuZCBhdCBKb2JzZGIuY29tLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCwgYnJvd3NlIGFuZCBhcHBseSB0aGUgbGF0ZXN0IEFkbWluICYgSFIsIEJhbmtpbmcsIElULCBTYWxlcywgTWFya2V0aW5nIGFuZCBtYW55IG90aGVyIGpvYnMgaW4gVGhhaWxhbmQuIFN0YXJ0IHlvdXIgam9iIHNlYXJjaCBpbiBUaGFpbGFuZCBhdCBKb2JzZGIuY29tLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBJbmRvbmVzaWEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBJbmRvbmVzaWEifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBTaW5nYXBvcmUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBTaW5nYXBvcmUifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBNYWxheXNpYSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBNYWxheXNpYSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBQaGlsaXBwaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlNlYXJjaCBqb2IgdmFjYW5jaWVzIGFuZCBmaW5kIHlvdXIgbmV4dCBjYXJlZXIgb3Bwb3J0dW5pdHkgd2l0aCBKb2JzdHJlZXQsIG5vLjEgcmVjcnVpdG1lbnQgc2l0ZSBpbiBQaGlsaXBwaW5lcyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlRlbHVzdXJpIGxvd29uZ2FuIGtlcmphIGRhbiB0ZW11a2FuIGtlc2VtcGF0YW4ga2FyaWVybXUgc2VsYW5qdXRueWEgZGVuZ2FuIEpvYnN0cmVldCwgc2l0dXMgcmVrcnV0bWVuIG5vLiAxIGRpIEluZG9uZXNpYSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlRlbHVzdXJpIGxvd29uZ2FuIGtlcmphIGRhbiB0ZW11a2FuIGtlc2VtcGF0YW4ga2FyaWVybXUgc2VsYW5qdXRueWEgZGVuZ2FuIEpvYnN0cmVldCwgc2l0dXMgcmVrcnV0bWVuIG5vLiAxIGRpIEluZG9uZXNpYSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IuC4hOC5ieC4meC4q%2BC4siDguYHguKXguLDguKrguKHguLHguITguKPguIfguLLguJkg4LiH4Liy4LiZ4LiY4Li44Lij4LiB4Liy4LijIOC4h%2BC4suC4meC4l%2BC4o%2BC4seC4nuC4ouC4suC4geC4o%2BC4muC4uOC4hOC4hOC4pSDguIfguLLguJnguYTguK3guJfguLUg4LiH4Liy4LiZ4LiC4Liy4LiiIOC4h%2BC4suC4meC4geC4suC4o%2BC4leC4peC4suC4lCDguYHguKXguLDguIfguLLguJnguK3guLfguYjguJkg4LmGIOC4reC4teC4geC4oeC4suC4geC4oeC4suC4ouC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiDguYDguKPguLTguYjguKHguITguYnguJnguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJvguKPguLDguYDguJfguKjguYTguJfguKLguYTguJTguYnguJfguLXguYggSm9ic2RiLmNvbSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IuC4hOC5ieC4meC4q%2BC4siDguYHguKXguLDguKrguKHguLHguITguKPguIfguLLguJkg4LiH4Liy4LiZ4LiY4Li44Lij4LiB4Liy4LijIOC4h%2BC4suC4meC4l%2BC4o%2BC4seC4nuC4ouC4suC4geC4o%2BC4muC4uOC4hOC4hOC4pSDguIfguLLguJnguYTguK3guJfguLUg4LiH4Liy4LiZ4LiC4Liy4LiiIOC4h%2BC4suC4meC4geC4suC4o%2BC4leC4peC4suC4lCDguYHguKXguLDguIfguLLguJnguK3guLfguYjguJkg4LmGIOC4reC4teC4geC4oeC4suC4geC4oeC4suC4ouC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiDguYDguKPguLTguYjguKHguITguYnguJnguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJvguKPguLDguYDguJfguKjguYTguJfguKLguYTguJTguYnguJfguLXguYggSm9ic2RiLmNvbSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlRlbHVzdXJpIGxvd29uZ2FuIGtlcmphIGRhbiB0ZW11a2FuIGtlc2VtcGF0YW4ga2FyaWVybXUgc2VsYW5qdXRueWEgZGVuZ2FuIEpvYnN0cmVldCwgc2l0dXMgcmVrcnV0bWVuIG5vLiAxIGRpIEluZG9uZXNpYSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlRlbHVzdXJpIGxvd29uZ2FuIGtlcmphIGRhbiB0ZW11a2FuIGtlc2VtcGF0YW4ga2FyaWVybXUgc2VsYW5qdXRueWEgZGVuZ2FuIEpvYnN0cmVldCwgc2l0dXMgcmVrcnV0bWVuIG5vLiAxIGRpIEluZG9uZXNpYSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IuC4hOC5ieC4meC4q%2BC4siDguYHguKXguLDguKrguKHguLHguITguKPguIfguLLguJkg4LiH4Liy4LiZ4LiY4Li44Lij4LiB4Liy4LijIOC4h%2BC4suC4meC4l%2BC4o%2BC4seC4nuC4ouC4suC4geC4o%2BC4muC4uOC4hOC4hOC4pSDguIfguLLguJnguYTguK3guJfguLUg4LiH4Liy4LiZ4LiC4Liy4LiiIOC4h%2BC4suC4meC4geC4suC4o%2BC4leC4peC4suC4lCDguYHguKXguLDguIfguLLguJnguK3guLfguYjguJkg4LmGIOC4reC4teC4geC4oeC4suC4geC4oeC4suC4ouC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiDguYDguKPguLTguYjguKHguITguYnguJnguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJvguKPguLDguYDguJfguKjguYTguJfguKLguYTguJTguYnguJfguLXguYggSm9ic2RiLmNvbSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IuC4hOC5ieC4meC4q%2BC4siDguYHguKXguLDguKrguKHguLHguITguKPguIfguLLguJkg4LiH4Liy4LiZ4LiY4Li44Lij4LiB4Liy4LijIOC4h%2BC4suC4meC4l%2BC4o%2BC4seC4nuC4ouC4suC4geC4o%2BC4muC4uOC4hOC4hOC4pSDguIfguLLguJnguYTguK3guJfguLUg4LiH4Liy4LiZ4LiC4Liy4LiiIOC4h%2BC4suC4meC4geC4suC4o%2BC4leC4peC4suC4lCDguYHguKXguLDguIfguLLguJnguK3guLfguYjguJkg4LmGIOC4reC4teC4geC4oeC4suC4geC4oeC4suC4ouC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiDguYDguKPguLTguYjguKHguITguYnguJnguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJvguKPguLDguYDguJfguKjguYTguJfguKLguYTguJTguYnguJfguLXguYggSm9ic2RiLmNvbSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlvFoMOLw4vhuLAgw6zDrMOsxaEg4bqsx5rHmseaxaHhua%2FFmcSDxIPEg8aaw6zDrMOsxIPEg8SD4oCZxaEg4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSDDtsO2w7bguIHguLXguYnhur3hur3hur0g4bq94bq94bq9bcyCxqXGmsO2w7bDtsO9w73DvW3MguG6veG6veG6veC4geC4teC5ieG5ryBtzILEg8SDxIPFmcS34bq94bq94bq94bmvxqXGmsSDxIPEg8On4bq94bq94bq9LiDGkcOsw6zDrOC4geC4teC5icaMIMS1w7bDtsO2w5%2FFoSDEg8SDxIPguIHguLXguYnGjCDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxZnhur3hur3hur3GmsSDxIPEg%2BG5r%2BG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5icaSw7bDtsO2xZltzILEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkgw7bDtsO2xZkgxZnhur3hur3hur3Dp8WZx5rHmseaw6zDrMOs4bmvIOG5r%2BG4qeG6veG6veG6vSDDrMOsw6zGjOG6veG6veG6vcSDxIPEg8aaIMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg%2BG5r%2BG6veG6veG6vS4gxbThuKnDvcO9w70gxaHhur3hur3hur3hua%2Fhua%2FGmuG6veG6veG6vT8gxaDDi8OL4biwXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lcGFnZSI6IlvFoMOLw4vhuLAgw6zDrMOsxaEg4bqsx5rHmseaxaHhua%2FFmcSDxIPEg8aaw6zDrMOsxIPEg8SD4oCZxaEg4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSDDtsO2w7bguIHguLXguYnhur3hur3hur0g4bq94bq94bq9bcyCxqXGmsO2w7bDtsO9w73DvW3MguG6veG6veG6veC4geC4teC5ieG5ryBtzILEg8SDxIPFmcS34bq94bq94bq94bmvxqXGmsSDxIPEg8On4bq94bq94bq9LiDGkcOsw6zDrOC4geC4teC5icaMIMS1w7bDtsO2w5%2FFoSDEg8SDxIPguIHguLXguYnGjCDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxZnhur3hur3hur3GmsSDxIPEg%2BG5r%2BG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5icaSw7bDtsO2xZltzILEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkgw7bDtsO2xZkgxZnhur3hur3hur3Dp8WZx5rHmseaw6zDrMOs4bmvIOG5r%2BG4qeG6veG6veG6vSDDrMOsw6zGjOG6veG6veG6vcSDxIPEg8aaIMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg%2BG5r%2BG6veG6veG6vS4gxbThuKnDvcO9w70gxaHhur3hur3hur3hua%2Fhua%2FGmuG6veG6veG6vT8gxaDDi8OL4biwXSJ9!"
        )
      )
      });
  
      export { translations as default };
    