import { Box } from 'braid-design-system';
import { memo } from 'react';

import useAnimatedWrapper, {
  type UseAnimatedWrapperProps,
} from 'src/hooks/useAnimatedWrapper';

import * as styles from './AnimatedWidth.css.ts';

const AnimatedWidth = ({
  children,
  changeKeys,
  changeDependsOn = 'child',
}: Pick<UseAnimatedWrapperProps, 'changeKeys' | 'changeDependsOn'> & {
  children: React.ReactNode;
}) => {
  const { style, parentRef, childRef, status } = useAnimatedWrapper({
    changeKeys,
    changeDependsOn,
    changeDimension: 'width',
  });

  const commonProps = {
    className: [
      styles.root,
      {
        [styles.active]: status !== 'idle',
      },
    ],
    style: { width: style },
  };

  return (
    <Box
      ref={parentRef}
      {...(changeDependsOn === 'child' ? commonProps : undefined)}
    >
      <Box
        ref={childRef}
        {...(changeDependsOn === 'parent' ? commonProps : undefined)}
      >
        {children}
      </Box>
    </Box>
  );
};

export default memo(AnimatedWidth);
