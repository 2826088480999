import type { DisplayTypeV5 } from '@seek/chalice-types';
import { JOB_DISPLAY_TYPE_QUERY_KEY } from '@seek/job-details-view';
import { parse } from 'query-string';
import { useMemo } from 'react';

import { useSelector } from 'src/store/react';

export const useLocationJobDisplayType = (): DisplayTypeV5 => {
  const locationDisplayType = useSelector(
    (state) => state.location.query[JOB_DISPLAY_TYPE_QUERY_KEY],
  );
  const queryDisplayType =
    typeof window !== 'undefined'
      ? parse(window.location.search)[JOB_DISPLAY_TYPE_QUERY_KEY]
      : '';
  return useMemo<DisplayTypeV5>(
    () => queryDisplayType || locationDisplayType,
    [queryDisplayType, locationDisplayType],
  );
};
