import { Box, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

export const MessageWrapper = ({
  children,
}: {
  children: ComponentProps<typeof Text>['children'];
}) => (
  <Box boxShadow="borderNeutralLight" borderRadius="large" padding="medium">
    <Text tone="secondary">{children}</Text>
  </Box>
);
