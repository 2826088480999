import { Box, Column, Columns, Stack } from 'braid-design-system';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';

import {
  HOMEPAGE_JOB_CARD_LOGO_HEIGHT,
  HOMEPAGE_JOB_CARD_LOGO_MAX_WIDTH,
} from './JobCard';

export const JobCardSkeleton: React.FC = () => (
  <Box borderRadius="large" boxShadow="borderNeutralLight" padding="medium">
    <Stack space="medium">
      <Columns space="small" collapseBelow="tablet" reverse>
        <Column width="content">
          <SkeletonLine
            type="text"
            width={HOMEPAGE_JOB_CARD_LOGO_MAX_WIDTH}
            height={HOMEPAGE_JOB_CARD_LOGO_HEIGHT}
          />
        </Column>

        <Column>
          <Stack space="small">
            <Stack space="xsmall">
              <SkeletonLine type="text" width={200} />
              <SkeletonLine type="text" width={175} />
            </Stack>
            <Stack space="small">
              <Stack space="xsmall">
                <SkeletonLine type="text" width={150} />
                <SkeletonLine type="text" width={150} />
              </Stack>
            </Stack>
          </Stack>
        </Column>
      </Columns>

      <SkeletonLine type="text" width={50} size="xsmall" />
    </Stack>
  </Box>
);
