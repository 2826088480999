import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  GetSkillsDocument,
  UpdateSkillsDocument,
  type Skill,
  type SkillInput,
} from 'src/graphql/graphql';
import { logger } from 'src/modules/logger';

type UpdateSkillError = 'UpdateSkillError' | 'SkillLimitReached';
export type UpdateSkillResponse = UpdateSkillError | 'UpdateSkillSuccess';

export const useSkillsMutations = (limit: number = 50) => {
  const [getSkills] = useLazyQuery(GetSkillsDocument, {
    fetchPolicy: 'network-only',
  });

  const [skillsMutation] = useMutation(UpdateSkillsDocument);

  const updateSkills = useCallback(
    async (newSkills: SkillInput[]) => {
      const result = await skillsMutation({
        variables: { input: { skills: newSkills } },
      });

      if (result.errors) {
        for (const error of result.errors) {
          logger.error({ err: error }, 'Could not update candidate skills');
        }
        return 'UpdateSkillError';
      }
      return 'UpdateSkillSuccess';
    },
    [skillsMutation],
  );

  const ensureSkillInput = ({
    keyword: { text, ontologyId },
  }: Skill): SkillInput => ({ keyword: { text, ontologyId } });

  const addSkill = useCallback(
    async (skill: SkillInput): Promise<UpdateSkillResponse> => {
      const skillsQueryResult = await getSkills();
      const skills = skillsQueryResult?.data?.viewer?.skills2 || [];

      if (skills.length >= limit) {
        return 'SkillLimitReached';
      }

      if (
        !skills.find(
          ({ keyword: { text, ontologyId } }) =>
            skill.keyword.ontologyId === ontologyId &&
            skill.keyword.text === text,
        )
      ) {
        const newSkills = skills.filter(
          (s) => s.keyword.text !== skill.keyword.text,
        );
        newSkills.push(skill);

        return updateSkills(newSkills.map((s) => ensureSkillInput(s)));
      }

      return 'UpdateSkillSuccess';
    },
    [getSkills, limit, updateSkills],
  );

  const removeSkill = useCallback(
    async (skill: Skill): Promise<UpdateSkillResponse> => {
      const skillsQueryResult = await getSkills();
      const skills = skillsQueryResult?.data?.viewer?.skills2 || [];

      if (
        !skills.find(
          ({ keyword: { text, ontologyId } }) =>
            skill.keyword.ontologyId === ontologyId &&
            skill.keyword.text === text,
        )
      ) {
        return 'UpdateSkillSuccess';
      }
      const newSkills = skills.filter(
        (s) => s.keyword.text !== skill.keyword.text,
      );

      return await updateSkills(newSkills.map((s) => ensureSkillInput(s)));
    },
    [getSkills, updateSkills],
  );

  return { addSkill, removeSkill };
};
