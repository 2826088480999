import { set as setLocalStorage, get as getLocalStorage } from 'local-storage';
import { useState } from 'react';

export const LAST_JOB_ID_STORAGE_KEY = 'lastJobId';

export const storeLastJobId = (jobId: string) =>
  setLocalStorage(LAST_JOB_ID_STORAGE_KEY, jobId);

export const useLastJobId = (): string | null => {
  const [lastJobId] = useState(() => {
    try {
      const lastJobIdFromStorage = getLocalStorage(LAST_JOB_ID_STORAGE_KEY);

      if (typeof lastJobIdFromStorage === 'string') {
        return lastJobIdFromStorage;
      }

      return null;
    } catch (e) {
      return null;
    }
  });

  return lastJobId;
};
