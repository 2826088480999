import { useQuery } from '@apollo/client';

import { useAppConfig } from 'src/config/appConfig';
import { JobDetailsWithPersonalisedDocument } from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { useSelector } from 'src/store/react';
import { selectSessionId } from 'src/store/selectors';

import { useSignedInDashboardContext } from '../../SignedInDashboardContextProvider';

export const useFetchJobDetails = () => {
  const { locale, zone, language, country } = useAppConfig();
  const timezone = useTimezone();
  const sessionId = useSelector(selectSessionId) ?? '';
  const { selectedJob, jobDetailsViewedCorrelationId } =
    useSignedInDashboardContext();

  const { data, error, loading, refetch } = useQuery(
    JobDetailsWithPersonalisedDocument,
    {
      variables: {
        jobId: selectedJob?.id || '',
        jobDetailsViewedCorrelationId,
        sessionId,
        zone,
        locale,
        languageCode: language,
        countryCode: country,
        timezone,
      },
      skip: !selectedJob?.id,
      ssr: false,
    },
  );

  return {
    jobDetails: data?.jobDetails,
    error,
    loading,
    refetchJobDetail: refetch,
  };
};
