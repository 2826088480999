
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIiwiUmVsb2FkIjoiUmVsb2FkIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGRhcGF0IGRpbXVhdCIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbXVhdG55YSwgZGFuIHNlZGFuZyBiZXJ1c2FoYSBtZW1wZXJiYWlraW55YS4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJDb2JhIG11YXQgdWxhbmcgYXRhdSBwaWxpaCBsb3dvbmdhbiBrZXJqYSBsYWluLiIsIlJlbG9hZCI6Ik11YXQgdWxhbmcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGRhcGF0IGRpbXVhdCIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbXVhdG55YSwgZGFuIHNlZGFuZyBiZXJ1c2FoYSBtZW1wZXJiYWlraW55YS4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJDb2JhIG11YXQgdWxhbmcgYXRhdSBwaWxpaCBsb3dvbmdhbiBrZXJqYSBsYWluLiIsIlJlbG9hZCI6Ik11YXQgdWxhbmcifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4LiU4LmJIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5geC4quC4lOC4h%2BC4q%2BC4meC5ieC4suC4meC4teC5ieC5hOC4lOC5iSDguILguJPguLDguJnguLXguYnguK3guKLguLnguYjguYPguJnguKPguLDguKvguKfguYjguLLguIfguJTguLPguYDguJnguLTguJnguIHguLLguKPguYHguIHguYnguYTguIIiLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiLguYLguKvguKXguJTguYPguKvguKHguYjguKvguKPguLfguK3guYDguKXguLfguK3guIHguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguK3guLfguYjguJkiLCJSZWxvYWQiOiLguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4LiU4LmJIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5geC4quC4lOC4h%2BC4q%2BC4meC5ieC4suC4meC4teC5ieC5hOC4lOC5iSDguILguJPguLDguJnguLXguYnguK3guKLguLnguYjguYPguJnguKPguLDguKvguKfguYjguLLguIfguJTguLPguYDguJnguLTguJnguIHguLLguKPguYHguIHguYnguYTguIIiLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiLguYLguKvguKXguJTguYPguKvguKHguYjguKvguKPguLfguK3guYDguKXguLfguK3guIHguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguK3guLfguYjguJkiLCJSZWxvYWQiOiLguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGRhcGF0IGRpbXVhdCIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbXVhdG55YSwgZGFuIHNlZGFuZyBiZXJ1c2FoYSBtZW1wZXJiYWlraW55YS4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJDb2JhIG11YXQgdWxhbmcgYXRhdSBwaWxpaCBsb3dvbmdhbiBrZXJqYSBsYWluLiIsIlJlbG9hZCI6Ik11YXQgdWxhbmcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGRhcGF0IGRpbXVhdCIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbXVhdG55YSwgZGFuIHNlZGFuZyBiZXJ1c2FoYSBtZW1wZXJiYWlraW55YS4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJDb2JhIG11YXQgdWxhbmcgYXRhdSBwaWxpaCBsb3dvbmdhbiBrZXJqYSBsYWluLiIsIlJlbG9hZCI6Ik11YXQgdWxhbmcifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4LiU4LmJIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5geC4quC4lOC4h%2BC4q%2BC4meC5ieC4suC4meC4teC5ieC5hOC4lOC5iSDguILguJPguLDguJnguLXguYnguK3guKLguLnguYjguYPguJnguKPguLDguKvguKfguYjguLLguIfguJTguLPguYDguJnguLTguJnguIHguLLguKPguYHguIHguYnguYTguIIiLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiLguYLguKvguKXguJTguYPguKvguKHguYjguKvguKPguLfguK3guYDguKXguLfguK3guIHguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguK3guLfguYjguJkiLCJSZWxvYWQiOiLguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4LiU4LmJIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5geC4quC4lOC4h%2BC4q%2BC4meC5ieC4suC4meC4teC5ieC5hOC4lOC5iSDguILguJPguLDguJnguLXguYnguK3guKLguLnguYjguYPguJnguKPguLDguKvguKfguYjguLLguIfguJTguLPguYDguJnguLTguJnguIHguLLguKPguYHguIHguYnguYTguIIiLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiLguYLguKvguKXguJTguYPguKvguKHguYjguKvguKPguLfguK3guYDguKXguLfguK3guIHguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguK3guLfguYjguJkiLCJSZWxvYWQiOiLguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIcifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiW8W04bq94bq94bq9IMOnw7bDtsO2x5rHmseaxprGjOC4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua%2FhuKnDrMOsw6zFoSDEtcO2w7bDtsOfXSIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJbxbThur3hur3hur0gw6fEg8SDxIPguIHguLXguYkn4bmvIMaaw7bDtsO2xIPEg8SDxowg4bmv4bipw6zDrMOsxaEgxZnDrMOsw6zEo%2BG4qeG5ryDguIHguLXguYnDtsO2w7bFtSDDn8eax5rHmuG5ryDFteG6veG6veG6vSfFmeG6veG6veG6vSDGjMO2w7bDtsOsw6zDrOC4geC4teC5icSjIMO2w7bDtseax5rHmsWZIMOf4bq94bq94bq9xaHhua8g4bmvw7bDtsO2IMaSw6zDrMOs6q2VIMOsw6zDrOG5ry5dIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiW%2BG5rsWZw73DvcO9IMWZ4bq94bq94bq9xprDtsO2w7bEg8SDxIPGjMOsw6zDrOC4geC4teC5icSjIMO2w7bDtsWZIMWh4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryDEg8SDxIPguIHguLXguYnDtsO2w7bhua%2FhuKnhur3hur3hur3FmSDEtcO2w7bDtsOfLl0iLCJSZWxvYWQiOiJbxZjhur3hur3GmsO2w7bEg8SDxoxdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiW8W04bq94bq94bq9IMOnw7bDtsO2x5rHmseaxprGjOC4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua%2FhuKnDrMOsw6zFoSDEtcO2w7bDtsOfXSIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJbxbThur3hur3hur0gw6fEg8SDxIPguIHguLXguYkn4bmvIMaaw7bDtsO2xIPEg8SDxowg4bmv4bipw6zDrMOsxaEgxZnDrMOsw6zEo%2BG4qeG5ryDguIHguLXguYnDtsO2w7bFtSDDn8eax5rHmuG5ryDFteG6veG6veG6vSfFmeG6veG6veG6vSDGjMO2w7bDtsOsw6zDrOC4geC4teC5icSjIMO2w7bDtseax5rHmsWZIMOf4bq94bq94bq9xaHhua8g4bmvw7bDtsO2IMaSw6zDrMOs6q2VIMOsw6zDrOG5ry5dIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoiW%2BG5rsWZw73DvcO9IMWZ4bq94bq94bq9xprDtsO2w7bEg8SDxIPGjMOsw6zDrOC4geC4teC5icSjIMO2w7bDtsWZIMWh4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryDEg8SDxIPguIHguLXguYnDtsO2w7bhua%2FhuKnhur3hur3hur3FmSDEtcO2w7bDtsOfLl0iLCJSZWxvYWQiOiJbxZjhur3hur3GmsO2w7bEg8SDxoxdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    