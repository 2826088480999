import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@22.10.1_babel-plugin-macros@3.1.0_terser@5_6yo5eb7pvr2wgxtrgchdbpdpuy/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2Sz3KCMBCH7zxFjnKIg6AgeOmbdGJYIQoJDVGoHd69QyozJvKnvfcG+X0Lu99m/b4RXltn9xR9OQidCmhxyiRQxQRP0I3IFcYDs3MPTuesh1fQJRVJU8YzrERl86F7eAKOQilR2kxkMAWclE3sDUKyLH9BYrOxk24shzGU6K+VjOPx/KjzhqUqtyOqoyOhl0yKK08xK0kGNrWxqZrdIUFU3EBaiYQKiEoQF49HK69Ezcb24JvjZnrcpzoqCiHtIs8sypeWF5g803yTMwW4rgiFvu1Gksqgzpp6ujH94MW15AfjmCQo8LyqNU9pgvxwa59GQ2NNxD7ys+eacTgf7+fj2IytmS+22GGVXBHGJ5aFPxNEgat+253zVkLKCKqpBOCI8BStStLix/3a9RJc/RdL4aTEKY1TImdUBq4NjMpc1Dkj9AfoJkQwPoiItv8itIg49v8qYvmjG/+390yKZsxtHIy6Dbbhottwye0rsF8C4jGgc7pvNsHe+M0GAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@22.10.1_babel-plugin-macros@3.1.0_terser@5_6yo5eb7pvr2wgxtrgchdbpdpuy/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var centerImage = '_1o0xsgzk';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';