import { useTranslations } from '@vocab/react';
import { ButtonLink } from 'braid-design-system';
import { useCallback } from 'react';

import { useReturnUrl } from 'src/hooks/useReturnUrl.ts';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import translations from './.vocab';

const SignInButton = () => {
  const { t } = useTranslations(translations);
  const SIGN_IN = t('Sign in');

  const signInLink = useReturnUrl({ mode: 'signin' });
  const analyticsFacade = useAnalyticsFacade();

  const onClickSignIn = useCallback(() => {
    analyticsFacade.signInPressed({
      linkContext: {
        linkPosition: 'body',
        linkText: SIGN_IN,
      },
    });
  }, [analyticsFacade, SIGN_IN]);

  return (
    <ButtonLink
      tone="neutral"
      variant="ghost"
      href={signInLink}
      onClick={onClickSignIn}
      rel="nofollow"
      data={{ automation: 'dashboardSignIn' }}
    >
      <span style={{ whiteSpace: 'nowrap' }}>{SIGN_IN}</span>
    </ButtonLink>
  );
};

export default SignInButton;
