import { Box, useSpace } from 'braid-design-system';
import throttle from 'lodash/throttle';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';

import useIsomorphicLayoutEffect from 'src/modules/useIsomorphicLayoutEffect';

import * as styles from './HorizontalScrollContainer.css';

// This was copied from the braid-design-system source code
// https://github.com/seek-oss/braid-design-system/blob/master/packages/braid-design-system/src/lib/components/Tabs/Tabs.tsx#L65
// TODO: Braid team are also working on a solution for this, they will release a new version in the coming version, then we could drop this custom component. Check https://seek-asia.slack.com/archives/CDL5VKRHN/p1732588240699479?thread_ts=1732587659.726029&cid=CDL5VKRHN
const HorizontalScrollContainer = ({ children }: { children: ReactNode }) => {
  const tabsRef = useRef<HTMLElement>(null);

  const { space, grid } = useSpace();
  const [showMask, setShowMask] = useState(true);

  // This must be called within a `useLayoutEffect` because `.scrollLeft`, `.scrollWidth` and `.offsetWidth` force a reflow
  // https://gist.github.com/paulirish/5d52fb081b3570c81e3a
  const updateMask = useCallback(() => {
    if (!tabsRef.current) {
      return;
    }

    setShowMask(
      tabsRef.current.scrollWidth -
        tabsRef.current.offsetWidth -
        tabsRef.current.scrollLeft >
        grid * space.small,
    );
  }, [tabsRef, setShowMask, grid, space]);

  const [throttleUpdateMask, setThrottleUpdateMask] = useState<ReturnType<
    typeof throttle<() => void>
  > | null>(null);

  useEffect(() => {
    setThrottleUpdateMask((previous) => {
      previous?.cancel();

      return throttle(
        () => {
          updateMask();
        },
        1000 / 60,
        {
          leading: true,
          trailing: true,
        },
      );
    });
  }, [updateMask]);

  useIsomorphicLayoutEffect(() => {
    if (!throttleUpdateMask) {
      return;
    }

    window.addEventListener('resize', throttleUpdateMask);

    return () => {
      window.removeEventListener('resize', throttleUpdateMask);
    };
  }, [throttleUpdateMask]);

  return (
    <Box
      position="relative"
      className={[
        styles.horizontalScrolling,
        { [styles.scrollingContainerMask]: showMask },
      ]}
      ref={tabsRef}
      onScroll={updateMask}
    >
      {children}
    </Box>
  );
};

export default HorizontalScrollContainer;
