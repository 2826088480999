export const LOGO_HEIGHT = 65;

const StandardCompanyLogo = () => (
  <svg
    viewBox="0 0 43 65"
    width="43"
    height={LOGO_HEIGHT}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m22.014 11.764v-3.8622h-15.449v3.8622h-3.8621v41.046h23.173v-41.046h-3.8621z"
      fill="#EAECF1"
    />
    <path d="m40.295 19.492h-14.419v33.322h14.419v-33.322z" fill="#D2D7DF" />
    <path
      d="m34.535 27.215h-2.8966c-0.2666 0-0.4827 0.2162-0.4827 0.4828v2.8966c0 0.2666 0.2161 0.4828 0.4827 0.4828h2.8966c0.2667 0 0.4828-0.2162 0.4828-0.4828v-2.8966c0-0.2666-0.2161-0.4828-0.4828-0.4828z"
      fill="#EAECF1"
    />
    <path
      d="m34.535 34.938h-2.8966c-0.2666 0-0.4827 0.2161-0.4827 0.4828v2.8966c0 0.2666 0.2161 0.4827 0.4827 0.4827h2.8966c0.2667 0 0.4828-0.2161 0.4828-0.4827v-2.8966c0-0.2667-0.2161-0.4828-0.4828-0.4828z"
      fill="#EAECF1"
    />
    <path
      d="m31.155 42.664h3.8621v3.3793c0 0.2665-0.2163 0.4828-0.4828 0.4828h-3.3793v-3.8621z"
      fill="#EAECF1"
    />
    <path
      d="m11.876 19.492h-2.8966c-0.26663 0-0.48277 0.2161-0.48277 0.4828v2.8966c0 0.2666 0.21614 0.4827 0.48277 0.4827h2.8966c0.2667 0 0.4828-0.2161 0.4828-0.4827v-2.8966c0-0.2667-0.2161-0.4828-0.4828-0.4828z"
      fill="#D2D7DF"
    />
    <path
      d="m11.876 27.215h-2.8966c-0.26663 0-0.48277 0.2162-0.48277 0.4828v2.8966c0 0.2666 0.21614 0.4828 0.48277 0.4828h2.8966c0.2667 0 0.4828-0.2162 0.4828-0.4828v-2.8966c0-0.2666-0.2161-0.4828-0.4828-0.4828z"
      fill="#D2D7DF"
    />
    <path
      d="m11.876 34.938h-2.8966c-0.26663 0-0.48277 0.2161-0.48277 0.4828v2.8966c0 0.2666 0.21614 0.4827 0.48277 0.4827h2.8966c0.2667 0 0.4828-0.2161 0.4828-0.4827v-2.8966c0-0.2667-0.2161-0.4828-0.4828-0.4828z"
      fill="#D2D7DF"
    />
    <path
      d="m19.6 19.492h-2.8966c-0.2667 0-0.4828 0.2161-0.4828 0.4828v2.8966c0 0.2666 0.2161 0.4827 0.4828 0.4827h2.8966c0.2666 0 0.4827-0.2161 0.4827-0.4827v-2.8966c0-0.2667-0.2161-0.4828-0.4827-0.4828z"
      fill="#D2D7DF"
    />
    <path
      d="m19.6 27.215h-2.8966c-0.2667 0-0.4828 0.2162-0.4828 0.4828v2.8966c0 0.2666 0.2161 0.4828 0.4828 0.4828h2.8966c0.2666 0 0.4827-0.2162 0.4827-0.4828v-2.8966c0-0.2666-0.2161-0.4828-0.4827-0.4828z"
      fill="#D2D7DF"
    />
    <path
      d="m19.6 34.938h-2.8966c-0.2667 0-0.4828 0.2161-0.4828 0.4828v2.8966c0 0.2666 0.2161 0.4827 0.4828 0.4827h2.8966c0.2666 0 0.4827-0.2161 0.4827-0.4827v-2.8966c0-0.2667-0.2161-0.4828-0.4827-0.4828z"
      fill="#D2D7DF"
    />
    <path
      d="m11.876 42.664h-2.8966c-0.26663 0-0.48277 0.2161-0.48277 0.4827v2.8966c0 0.2667 0.21614 0.4828 0.48277 0.4828h2.8966c0.2667 0 0.4828-0.2161 0.4828-0.4828v-2.8966c0-0.2666-0.2161-0.4827-0.4828-0.4827z"
      fill="#D2D7DF"
    />
    <path
      d="m19.6 42.664h-2.8966c-0.2667 0-0.4828 0.2161-0.4828 0.4827v2.8966c0 0.2667 0.2161 0.4828 0.4828 0.4828h2.8966c0.2666 0 0.4827-0.2161 0.4827-0.4828v-2.8966c0-0.2666-0.2161-0.4827-0.4827-0.4827z"
      fill="#D2D7DF"
    />
    <path d="m42.741 52.812h-42.483v4.0625h42.483v-4.0625z" fill="#D2D7DF" />
  </svg>
);

export default StandardCompanyLogo;
