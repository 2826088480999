import type { SearchParams } from '@seek/chalice-types';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import querystring from 'query-string';

import { convertToApiQuery } from 'src/modules/seek-jobs-search-query';
import type { Country } from 'src/types/globals';

import type { SavedSearch } from './types';

const sensitiveKeys = [
  'classification',
  'subclassification',
  'daterange',
  'keywords',
  'salaryrange',
  'salarytype',
  'where',
  'worktype',
  'workarrangement',
  'distance',
  'sortmode',
];

type DefaultParams = Pick<
  SearchParams,
  'daterange' | 'salaryrange' | 'salarytype' | 'sortmode'
>;

export const defaultSearchQueryParamValues: DefaultParams = {
  daterange: '',
  salaryrange: '0-',
  salarytype: 'annually',
  sortmode: 'KeywordRelevance',
};

const isDefaultParamValue = (
  val: Partial<SearchParams>,
  key: string,
): boolean => defaultSearchQueryParamValues[key as keyof DefaultParams] === val;

const getNonDefaultParams = (
  searchQuery: SearchParams,
  country: Country = 'AU',
) => {
  // Fix me: There are currently two salaryType implementation namely annual & annually.
  // This causes the savedSearched to not work properly in some cases when savedSearches contains the salaryType filter.
  // Current iOS implementation uses "annually" whereby web uses "annual" when savedSearches is saved.
  const normalisedQuery = {
    ...searchQuery,
    salarytype:
      searchQuery.salarytype?.toString() === 'annual'
        ? 'annually'
        : searchQuery.salarytype,
    workarrangement: searchQuery.workarrangement?.split(',').sort().join(','),
    worktype: searchQuery.worktype?.split(',').sort().join(','),
  };

  return omitBy<Partial<SearchParams>>(
    pick(
      convertToApiQuery({ country, searchQuery: normalisedQuery }),
      sensitiveKeys,
    ),
    isDefaultParamValue,
  );
};

export default (
  currentSearchQuery: SearchParams,
  savedSearches: SavedSearch[] | null,
  country: Country,
): boolean => {
  if (!Boolean(savedSearches?.length)) {
    return false;
  }

  const cleanCurrentQuery = getNonDefaultParams(currentSearchQuery, country);

  return savedSearches!.some(({ query: { searchQueryString } }) => {
    const parsedQuery = querystring.parseUrl(`?${searchQueryString}`)
      .query as SearchParams;
    return isEqual(
      cleanCurrentQuery,
      getNonDefaultParams(parsedQuery, country),
    );
  });
};
