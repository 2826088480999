import { useTranslations } from '@vocab/react';
import { Heading, Hidden, Stack } from 'braid-design-system';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import { useIsHomepageNoCompanyCarouselEnabled } from 'src/store/featureFlags/hooks';

import translations from './.vocab';
import { LoadingRecommendations } from './LoadingRecommendations';
import { RecommendedJobCard } from './RecommendedJobCard';
import {
  RECOMMENDATIONS_LIMIT,
  TOP_SECTION_LIMIT,
  useHomeRecommendations,
} from './useHomeRecommendations';

const LOADING_SKELETON_COUNT = RECOMMENDATIONS_LIMIT - TOP_SECTION_LIMIT;

const MoreRecommendationsContent = () => {
  const { moreJobs, loading } = useHomeRecommendations();
  const { t } = useTranslations(translations);
  const isHomepageNoCompanyCarouselEnabled =
    useIsHomepageNoCompanyCarouselEnabled();

  if (loading) {
    return (
      <LoadingRecommendations
        length={LOADING_SKELETON_COUNT}
        keyPrefix="moreRecs"
      />
    );
  }

  return moreJobs.length ? (
    <Stack space="small">
      {!isHomepageNoCompanyCarouselEnabled && (
        <Hidden above="tablet" data={{ automation: 'moreJobsForYouHeading' }}>
          <Heading level="4" weight="weak">
            {t('More jobs for you')}
          </Heading>
        </Hidden>
      )}
      <Stack space="xsmall">
        {moreJobs.map((job, index) => (
          <RecommendedJobCard
            key={`recommendedJob_${job.id}`}
            jobInfo={job}
            position={TOP_SECTION_LIMIT + index + 1}
          />
        ))}
      </Stack>
    </Stack>
  ) : null;
};

export const MoreRecommendations = () => {
  const { loading } = useHomeRecommendations();
  return (
    <AnimatedHeight changeKeys={[loading]}>
      <MoreRecommendationsContent />
    </AnimatedHeight>
  );
};
