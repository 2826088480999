import { useZoneFeatures } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectAuthenticated } from 'src/store/selectors';

export const useIsPDPAConsentRequired = () => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const { PDPA_CONSENT_REQUIRED } = useZoneFeatures();
  const isPDPAConsentRequired =
    (PDPA_CONSENT_REQUIRED && !isAuthenticated) ?? false;

  return { isPDPAConsentRequired };
};
