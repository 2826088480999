import { IconRenderer } from 'braid-design-system';

export const ClockIcon = () => (
  <>
    <IconRenderer>
      {({ className }) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
          className={className}
          aria-hidden
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 6C1 3.22727 3.22727 1 6 1C8.77273 1 11 3.22727 11 6C11 8.77273 8.77273 11 6 11C3.22727 11 1 8.77273 1 6ZM6.45455 5.72727L8 6.5C8.22727 6.63636 8.31818 6.90909 8.22727 7.09091C8.13636 7.27273 8 7.36364 7.81818 7.36364C7.72727 7.36364 7.68182 7.36364 7.63636 7.31818L5.81818 6.40909C5.63636 6.31818 5.54545 6.18182 5.54545 6V3.27273C5.54545 3 5.72727 2.81818 6 2.81818C6.27273 2.81818 6.45455 3 6.45455 3.27273V5.72727Z"
          />
        </svg>
      )}
    </IconRenderer>
  </>
);
