import { useTranslations } from '@vocab/react';
import { Box, Heading, Inline, Stack, Text } from 'braid-design-system';

import RegisterButton from '../../RegisterButton.tsx';
import SignInButton from '../../SignInButton.tsx';
import translations from '../.vocab';

import BriefcaseWithStarSvg from './BriefcaseWithStar.svg.tsx';

export const SignInRegisterCTACard = () => {
  const { t } = useTranslations(translations);
  return (
    <Box
      borderRadius="large"
      padding="gutter"
      background="neutralLight"
      height="full"
      display="flex"
      flexDirection="column"
      justifyContent="spaceBetween"
      alignItems="flexStart"
    >
      <Box
        display="flex"
        justifyContent="spaceBetween"
        flexDirection={{ mobile: 'column', tablet: 'rowReverse' }}
        width="full"
      >
        <Box
          paddingBottom={{ mobile: 'small', tablet: 'none' }}
          paddingLeft="xsmall"
        >
          <BriefcaseWithStarSvg />
        </Box>
        <Stack space="medium">
          <Heading level="4">{t('More jobs for you')}</Heading>
          <Text>{t('Sign in and get tailored recommendations.')}</Text>
        </Stack>
      </Box>
      <Box paddingTop={{ mobile: 'medium', tablet: 'small' }}>
        <Inline space="small" alignY="center">
          <SignInButton />
          <RegisterButton />
        </Inline>
      </Box>
    </Box>
  );
};

export default SignInRegisterCTACard;
