
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiUmVjb21tZW5kZWQgYmFzZWQgb24geW91ciBhY3Rpdml0eSIsIk1vcmUgam9icyBmb3IgeW91IjoiTW9yZSBqb2JzIGZvciB5b3UiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlNpZ24gaW4gYW5kIGdldCB0YWlsb3JlZCByZWNvbW1lbmRhdGlvbnMuIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiRGlyZWtvbWVuZGFzaWthbiBiZXJkYXNhcmthbiBha3Rpdml0YXMgQW5kYSIsIk1vcmUgam9icyBmb3IgeW91IjoiTG93b25nYW4ga2VyamEgbGFpbm55YSB1bnR1ayBBbmRhIiwiU2lnbiBpbiBhbmQgZ2V0IHRhaWxvcmVkIHJlY29tbWVuZGF0aW9ucy4iOiJNYXN1ayBkYW4gZGFwYXRrYW4gcmVrb21lbmRhc2kgeWFuZyBkaXNlc3VhaWthbiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiRGlyZWtvbWVuZGFzaWthbiBiZXJkYXNhcmthbiBha3Rpdml0YXMgQW5kYSIsIk1vcmUgam9icyBmb3IgeW91IjoiTG93b25nYW4ga2VyamEgbGFpbm55YSB1bnR1ayBBbmRhIiwiU2lnbiBpbiBhbmQgZ2V0IHRhaWxvcmVkIHJlY29tbWVuZGF0aW9ucy4iOiJNYXN1ayBkYW4gZGFwYXRrYW4gcmVrb21lbmRhc2kgeWFuZyBkaXNlc3VhaWthbiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5Ijoi4LmB4LiZ4Liw4LiZ4Liz4LmC4LiU4Lii4Lit4LmJ4Liy4LiH4Lit4Li04LiH4LiI4Liy4LiB4LiB4Li04LiI4LiB4Lij4Lij4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiTW9yZSBqb2JzIGZvciB5b3UiOiLguKHguLXguIfguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKHguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IuC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC5geC4peC4sOC4o%2BC4seC4muC4hOC4s%2BC5geC4meC4sOC4meC4s%2BC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4k%2BC5guC4lOC4ouC5gOC4ieC4nuC4suC4sCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5Ijoi4LmB4LiZ4Liw4LiZ4Liz4LmC4LiU4Lii4Lit4LmJ4Liy4LiH4Lit4Li04LiH4LiI4Liy4LiB4LiB4Li04LiI4LiB4Lij4Lij4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiTW9yZSBqb2JzIGZvciB5b3UiOiLguKHguLXguIfguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKHguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IuC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC5geC4peC4sOC4o%2BC4seC4muC4hOC4s%2BC5geC4meC4sOC4meC4s%2BC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4k%2BC5guC4lOC4ouC5gOC4ieC4nuC4suC4sCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiRGlyZWtvbWVuZGFzaWthbiBiZXJkYXNhcmthbiBha3Rpdml0YXMgQW5kYSIsIk1vcmUgam9icyBmb3IgeW91IjoiTG93b25nYW4ga2VyamEgbGFpbm55YSB1bnR1ayBBbmRhIiwiU2lnbiBpbiBhbmQgZ2V0IHRhaWxvcmVkIHJlY29tbWVuZGF0aW9ucy4iOiJNYXN1ayBkYW4gZGFwYXRrYW4gcmVrb21lbmRhc2kgeWFuZyBkaXNlc3VhaWthbiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiRGlyZWtvbWVuZGFzaWthbiBiZXJkYXNhcmthbiBha3Rpdml0YXMgQW5kYSIsIk1vcmUgam9icyBmb3IgeW91IjoiTG93b25nYW4ga2VyamEgbGFpbm55YSB1bnR1ayBBbmRhIiwiU2lnbiBpbiBhbmQgZ2V0IHRhaWxvcmVkIHJlY29tbWVuZGF0aW9ucy4iOiJNYXN1ayBkYW4gZGFwYXRrYW4gcmVrb21lbmRhc2kgeWFuZyBkaXNlc3VhaWthbiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5Ijoi4LmB4LiZ4Liw4LiZ4Liz4LmC4LiU4Lii4Lit4LmJ4Liy4LiH4Lit4Li04LiH4LiI4Liy4LiB4LiB4Li04LiI4LiB4Lij4Lij4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiTW9yZSBqb2JzIGZvciB5b3UiOiLguKHguLXguIfguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKHguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IuC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC5geC4peC4sOC4o%2BC4seC4muC4hOC4s%2BC5geC4meC4sOC4meC4s%2BC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4k%2BC5guC4lOC4ouC5gOC4ieC4nuC4suC4sCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5Ijoi4LmB4LiZ4Liw4LiZ4Liz4LmC4LiU4Lii4Lit4LmJ4Liy4LiH4Lit4Li04LiH4LiI4Liy4LiB4LiB4Li04LiI4LiB4Lij4Lij4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiTW9yZSBqb2JzIGZvciB5b3UiOiLguKHguLXguIfguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKHguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IuC4peC4h%2BC4iuC4t%2BC5iOC4reC5gOC4guC5ieC4suC5g%2BC4iuC5ieC5geC4peC4sOC4o%2BC4seC4muC4hOC4s%2BC5geC4meC4sOC4meC4s%2BC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4k%2BC5guC4lOC4ouC5gOC4ieC4nuC4suC4sCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDDn8SDxIPFoeG6veG6vcaMIMO2w7bguIHguLXguYkgw73DvcO2w7bHmseaxZkgxIPEg8On4bmvw6zDrOG5vcOsw6zhua%2FDvcO9XSIsIk1vcmUgam9icyBmb3IgeW91IjoiW%2BG5gsO2w7bFmeG6veG6vSDEtcO2w7bDn8WhIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlvFoMOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDEg8SDxIPguIHguLXguYnGjCDEo%2BG6veG6veG6veG5ryDhua%2FEg8SDxIPDrMOsw6zGmsO2w7bDtsWZ4bq94bq94bq9xowgxZnhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaMxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBiYXNlZCBvbiB5b3VyIGFjdGl2aXR5IjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDDn8SDxIPFoeG6veG6vcaMIMO2w7bguIHguLXguYkgw73DvcO2w7bHmseaxZkgxIPEg8On4bmvw6zDrOG5vcOsw6zhua%2FDvcO9XSIsIk1vcmUgam9icyBmb3IgeW91IjoiW%2BG5gsO2w7bFmeG6veG6vSDEtcO2w7bDn8WhIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJTaWduIGluIGFuZCBnZXQgdGFpbG9yZWQgcmVjb21tZW5kYXRpb25zLiI6IlvFoMOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDEg8SDxIPguIHguLXguYnGjCDEo%2BG6veG6veG6veG5ryDhua%2FEg8SDxIPDrMOsw6zGmsO2w7bDtsWZ4bq94bq94bq9xowgxZnhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaMxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    