import { useSelector } from '../react';

import { selectFeatureFlag } from './selectors';

export const useIsHomepageMobileWebRedesignEnabled = () =>
  useSelector(selectFeatureFlag('homepageMobileWebRedesign'));

export const useIsHomepageNoCompanyCarouselEnabled = () => {
  const isHomepageNoCompanyCarouselEnabled = useSelector(
    selectFeatureFlag('homepageNoCompanyCarousel'),
  );
  const isHomepageMobileWebRedesignEnabled =
    useIsHomepageMobileWebRedesignEnabled();

  return (
    isHomepageNoCompanyCarouselEnabled && isHomepageMobileWebRedesignEnabled
  );
};
