import { metrics } from '@seek/metrics-js';
import { Box, Drawer } from 'braid-design-system';
import { useEffect } from 'react';

import JobDetailError from 'src/components/JobDetailError/JobDetailError';
import PageLoader from 'src/components/PageLoader/PageLoader';
import { logger } from 'src/modules/logger';
import type { JobDetails } from 'src/store/jobdetails/types';

import { useSignedInDashboardContext } from '../../SignedInDashboardContextProvider';

import { JobDetailsViewContent } from './JobDetailsViewContent';
import { useFetchJobDetails } from './useFetchJobDetails';

export const DrawerContent = () => {
  const { error, loading, jobDetails, refetchJobDetail } = useFetchJobDetails();

  useEffect(() => {
    if (error) {
      logger.error(error);
      metrics.count('ui_error', [`type:job_details_drawer`]);
    }
  }, [error]);

  if (loading) {
    return (
      <Box alignItems="center">
        <PageLoader />
      </Box>
    );
  }

  if (error) {
    return (
      <Box alignItems="center">
        <JobDetailError onReloadClick={refetchJobDetail} />
      </Box>
    );
  }
  if (!jobDetails) {
    return null;
  }

  return <JobDetailsViewContent jobDetails={jobDetails as JobDetails} />;
};

export const JobDetailsViewDrawer = () => {
  const { selectedJob, setSelectedJob } = useSignedInDashboardContext();

  return (
    selectedJob && (
      <Drawer
        onClose={() => setSelectedJob(null)}
        id="homepageDrawer"
        title=""
        open={Boolean(selectedJob)}
      >
        <DrawerContent />
      </Drawer>
    )
  );
};
