import type { JobDetailsView } from '@seek/job-details-view';
import { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { tabImpression } from 'src/modules/tabImpression';
import { cisImpression } from 'src/store/jobdetails';
import { useDispatch } from 'src/store/react';
import { selectSolMetadata } from 'src/store/selectors';

type Callback = Required<
  React.ComponentProps<typeof JobDetailsView>
>['onAnalyticsEvent'];

const useJDVTracking = () => {
  const solMetadata = useSelector(selectSolMetadata);
  const hotjarTimer = useRef<number>(0);
  const isSourcrClicked = useRef(false);
  const startHotjarTimer = useCallback(() => {
    hotjarTimer.current = window.setTimeout(
      () =>
        !isSourcrClicked.current &&
        window.hj('trigger', 'sourcr_non_engagement'),
      30000,
    );
  }, [isSourcrClicked]);
  useEffect(
    () => () => {
      clearTimeout(hotjarTimer.current);
    },
    [hotjarTimer],
  );

  const dispatch = useDispatch();
  const analyticsFacade = useAnalyticsFacade();
  const experimentFlightId = solMetadata?.tags?.mordor__flights ?? '';

  return useCallback<Callback>(
    (evt) => {
      switch (evt.type) {
        case 'COMPANY_PROFILE_LINK_CLICK': {
          analyticsFacade.linkClicked({
            linkName: 'job-details-company-profile-clicked',
            href: evt.href,
            experimentFlightId,
          });
          return;
        }
        case 'COMPANY_PROFILE_WIDGET_IMPRESSION': {
          analyticsFacade.companyProfileWidgetImpression();
          return;
        }
        case 'COMPANY_PROFILE_WIDGET_ACCORDION_LINK_CLICK': {
          analyticsFacade.companyProfileWidgetAccordionLinkClicked({
            linkAction: evt.linkAction,
          });
          return;
        }
        case 'COMPANY_PROFILE_WIDGET_LINK_CLICK': {
          analyticsFacade.companyProfileWidgetLinkClicked({
            linkPosition: evt.linkPosition,
          });
          return;
        }
        case 'COMPANY_PROFILE_WIDGET_REVIEWS_LINK_CLICK': {
          analyticsFacade.companyProfileWidgetReviewsLinkClicked({
            linkPosition: evt.linkPosition,
          });
          return;
        }
        case 'COMPANY_REVIEWS_LINK_CLICK': {
          analyticsFacade.linkClicked({
            linkName: 'job-details-company-reviews-clicked',
            href: evt.href,
            experimentFlightId,
          });
          return;
        }
        case 'COMPANY_JOB_SEARCH_LINK_CLICK': {
          analyticsFacade.linkClicked({
            linkName: 'job-details-more-jobs-by-company-clicked',
            href: evt.href,
            experimentFlightId,
          });
          return;
        }
        case 'CONTACT_MATCH_IMPRESSION': {
          const action = cisImpression(evt.contactTypes);
          dispatch(action);
          return;
        }
        case 'CONTACT_MATCH_INTERACTION': {
          analyticsFacade.contactMatchClicked({
            jobContactAction: evt.eventType,
            jobContactType: evt.contactType,
            experimentFlightId,
          });
          return;
        }
        case 'JOB_TITLE_LINK_CLICK': {
          analyticsFacade.linkClicked({
            linkName: 'job-details-title-clicked',
            href: evt.href,
          });
          return;
        }
        case 'SALARY_BADGE_CLICKED': {
          analyticsFacade.salaryBadgeClicked();
          return;
        }
        case 'SHARE_LINK_CLICK': {
          analyticsFacade.shareLinkClicked({
            jobShareType: evt.channel,
            experimentFlightId,
          });
          return;
        }
        case 'TOP_APPLICANT_BADGE_IMPRESSION': {
          dispatch(tabImpression());
          return;
        }
        case 'SOURCR_IMPRESSION': {
          analyticsFacade.sourcrImpression();
          startHotjarTimer();
          return;
        }
        case 'SOURCR_CLICKED': {
          analyticsFacade.sourcrClicked();
          window.hj('trigger', 'sourcr_engagement');
          isSourcrClicked.current = true;
          return;
        }
        case 'SKILLS_MATCH_SIGN_IN_PRESSED': {
          analyticsFacade.skillsMatchSignInPressed({
            currentPageSection: evt.currentPageSection,
          });
          return;
        }
        case 'SKILLS_MATCH_REGISTER_PRESSED': {
          analyticsFacade.skillsMatchRegisterPressed({
            currentPageSection: evt.currentPageSection,
          });
          return;
        }
        case 'SKILLS_MATCH_FEEDBACK_PRESSED': {
          analyticsFacade.skillsMatchFeedbackPressed({
            linkAction: evt.linkAction,
          });
          return;
        }
        case 'SKILLS_MATCH_ALERT': {
          analyticsFacade.skillsMatchAlert({
            currentPageSection: evt.currentPageSection,
            alertShownTone: evt.alertShownTone,
            alertShownType: evt.alertShownType,
            alertShownMessage: evt.alertShownMessage,
          });
          return;
        }
        case 'SKILLS_MATCH_PROFILE_UPDATED': {
          analyticsFacade.skillsMatchProfileUpdated({
            currentPageSection: evt.currentPageSection,
            updateType: evt.updateType,
            actionOrigin: evt.actionOrigin,
            skillsAdded: evt.skillsAdded,
            skillsStartTotal: evt.skillsStartTotal,
            skillsFromSuggestedTotal: evt.skillsFromSuggestedTotal,
            skillsCompleteTotal: evt.skillsCompleteTotal,
          });
          return;
        }
        case 'SKILLS_MATCH_ACCORDIAN_PRESSED': {
          analyticsFacade.skillsMatchAccordionPressed({
            linkAction: evt.linkAction,
            linkSection: evt.linkSection,
          });
          return;
        }
      }
    },
    [analyticsFacade, dispatch, experimentFlightId, startHotjarTimer],
  );
};

export default useJDVTracking;
