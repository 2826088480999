import { useTranslations } from '@vocab/react';

import translations from '../.vocab';
// @ts-expect-error
import SeekIcon from '../Campaign/SEEK_AYSF.png';
// @ts-expect-error
import seekretImage from '../Campaign/Seekret.png';
import type { BannerProps } from '../HomePageBanner';

const useSeekretCampaignContent = (): BannerProps => {
  const { t } = useTranslations(translations);
  return {
    text: {
      title: t('Better job matches.'),
      subTitle: {
        imageUrl: SeekIcon,
        altText: 'seek and you shall find',
      },
      inverted: true,
    },
    background: {
      color: '#2A0116',
      imageUrl: seekretImage,
      altText: 'The SEEKRET',
    },
  };
};

export default useSeekretCampaignContent;
