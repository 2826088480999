import { type ApolloError, useQuery } from '@apollo/client';
import { useHubble } from '@seek/hubble';
import { useEffect, useState } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import {
  GetLoggedOutRecommendationsDocument,
  type GetLoggedOutRecommendationsQuery,
} from 'src/graphql/graphql';
import { useLastJobId } from 'src/hooks/useLastJobId';
import { useTimezone } from 'src/hooks/useTimezone';

export const useLoggedOutRecommendations = (): UseLoggedOutRecommendations => {
  const [isInitialisedInClientRender, setIsInitialisedInClientRender] =
    useState(false);
  const { zone, locale } = useAppConfig();
  const timezone = useTimezone();
  const hubble = useHubble();
  const visitorId = hubble.visitorId();

  const lastJobId = useLastJobId();

  /**
   * We have decided to render the loading state both
   * when we render this on the server and client side.
   *
   * Without this - the loading state will be set to "false" in server side
   * since we can't get the lastJobId from local storage and thus skipping the
   * whole query. This will cause a hydration issue when we render this on the client side.
   */
  useEffect(() => {
    setIsInitialisedInClientRender(true);
  }, []);

  const { data, error, loading } = useQuery(
    GetLoggedOutRecommendationsDocument,
    {
      variables: {
        zone,
        visitorId,
        limit: 5,
        locale,
        timezone,
        jobId: lastJobId as string, // Need to cast this as a string to make TS happy. In reality, this should never be undefined because of the skip condition below
      },
      ssr: false,
      fetchPolicy: 'cache-first',
      skip: !lastJobId,
    },
  );

  const recommendedJobs = data?.loggedOutRecommendedJobs;
  const recommendations = !error
    ? // Global jobs can contain a null item. Null item means that we can't fetch the job details for that job because of an error.
      // Thus, we need to filter out the null items in both global job and job.
      (recommendedJobs?.globalJobs ?? []).filter(
        (globalJob): globalJob is LoggedOutRecommendation =>
          Boolean(globalJob !== null && globalJob.job !== null),
      )
    : [];

  const solMetadata =
    !error && recommendedJobs?.solMetadata ? recommendedJobs.solMetadata : null;

  return {
    recommendations,
    loading: !isInitialisedInClientRender || loading,
    solMetadata,
    error,
  };
};

type LoggedOutRecommendedJobs = NonNullable<
  GetLoggedOutRecommendationsQuery['loggedOutRecommendedJobs']
>;

type GlobalJob = NonNullable<
  NonNullable<LoggedOutRecommendedJobs['globalJobs']>[number]
>;

type Job = NonNullable<GlobalJob['job']>;

export interface LoggedOutRecommendation {
  solMetadata: GlobalJob['solMetadata'];
  job: Job;
}

interface UseLoggedOutRecommendations {
  recommendations: LoggedOutRecommendation[];
  loading: boolean;
  solMetadata: any | null;
  error?: ApolloError;
}
