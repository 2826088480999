import { useTranslations } from '@vocab/react';
import { ButtonIcon, IconBookmark } from 'braid-design-system';
import type { ComponentProps } from 'react';

import translation from './.vocab';

export const SaveJobButton = ({
  isSaved,
  id,
  onClick,
}: {
  isSaved: boolean;
  onClick: ComponentProps<typeof ButtonIcon>['onClick'];
  id: ComponentProps<typeof ButtonIcon>['id'];
}) => {
  const { t } = useTranslations(translation);
  const buttonText = isSaved ? t('Unsave') : t('Save');

  return (
    <ButtonIcon
      id={id}
      variant="transparent"
      icon={
        <IconBookmark
          tone={isSaved ? 'brandAccent' : undefined}
          active={isSaved}
        />
      }
      label={buttonText}
      onClick={onClick}
    />
  );
};

export default SaveJobButton;
