import { Box } from 'braid-design-system';

import * as styles from './LazyLoadedImg.css';

/* `LazyLoadedImg` was replicated from metropolis job card component
for displaying images with lazy loading behavior.
It styles to ensure the image container fully flexible within its parent
container and occupies the full height available */
export const LazyLoadedImg = ({ src, alt }: { src: string; alt?: string }) => (
  <Box className={styles.brandLogo}>
    <Box display="flex" height="full">
      <img
        className={styles.fitParentContainer}
        src={src}
        alt={alt}
        loading="lazy"
      />
    </Box>
  </Box>
);
