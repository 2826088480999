import { Box, Stack } from 'braid-design-system';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';

import { LOGO_HEIGHT } from '../components/StandardCompanyLogo/StandardCompanyLogo';

export const RecsJobCardSkeleton = () => (
  <Box
    boxShadow="borderNeutralLight"
    borderRadius="large"
    padding="medium"
    data={{ automation: 'recsJobCardSkeleton' }}
    height="full"
  >
    <Stack space="small">
      <SkeletonLine type="text" width={100} height={LOGO_HEIGHT} />
      <SkeletonLine type="text" width={180} />
      <SkeletonLine type="text" width={260} />
      <SkeletonLine type="text" width={200} />
      <SkeletonLine type="text" width={60} size="small" />
    </Stack>
  </Box>
);
