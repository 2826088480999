import { useCallback, useEffect, useRef } from 'react';

import { useMetricsTimerContext } from 'src/modules/MetricsTimer/MetricsTimerContext';

export const useStopTimerOnComponentVisibleControl = ({
  actionName,
  additionalTags,
}: {
  actionName: string;
  additionalTags?: string[];
}) => {
  const metrics = useMetricsTimerContext();
  useEffect(() => {
    metrics.stopTimer(actionName, additionalTags);

    // Context does not need to be in the dependency array, actionName should not change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionName, additionalTags]);
};

export const useStopTimerOnComponentVisible = <T extends HTMLElement>({
  actionName,
  additionalTags,
}: {
  actionName: string;
  additionalTags?: string[];
}) => {
  const metrics = useMetricsTimerContext();
  const observerRef = useRef<IntersectionObserver | null>(null);

  const refCallback = useCallback(
    (node: T | null) => {
      if (observerRef.current) {
        observerRef.current.disconnect(); // Disconnect previous observer if any
      }

      if (node !== null) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              metrics.stopTimer(actionName, additionalTags ?? []);
              observer.disconnect();
            }
          },
          { threshold: 0.1 },
        );

        observer.observe(node);
        observerRef.current = observer; // Save the current observer
      }
    },

    // Context does not need to be in the dependency array, actionName should not change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionName, additionalTags],
  );

  useEffect(
    () =>
      // Clean up the observer when the component unmounts
      () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      },
    [],
  );

  return refCallback;
};
