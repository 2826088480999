import { Box, Heading, Inline, Stack, Text } from 'braid-design-system';
import {
  forwardRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from 'react';

interface Props {
  heading: string;
  Illustration: ElementType;
  description?: ReactNode;
  actionButton?: ReactElement;
  dataAutomation?: string;

  ref?: HTMLElement;
}

export const IllustratedError = forwardRef<HTMLDivElement, Props>(
  (
    { heading, description, Illustration, dataAutomation, actionButton },
    ref,
  ) => (
    <Box
      ref={ref}
      component="section"
      padding="large"
      data-automation={dataAutomation}
    >
      <Stack space="large" align="center">
        <Illustration />
        <Heading level="3" align="center">
          {heading}
        </Heading>
        {description ? (
          <Text tone="secondary" align="center">
            {description}
          </Text>
        ) : null}
        {Boolean(actionButton) ? (
          <Inline space="none">{actionButton}</Inline>
        ) : null}
      </Stack>
    </Box>
  ),
);
