import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import type { JobDetails } from 'src/store/jobdetails/types';

import { useLocationJobDisplayType } from './useLocationJobDisplayType';

export const useJobDetailsSolMetadataReference = (jobDetails: JobDetails) => {
  const displayType = useLocationJobDisplayType();
  const jobId = jobDetails?.job?.id;

  const solMetadataReference = useSolMetadataReference({
    jobId,
    isFeatured: displayType === 'promoted',
  });

  return solMetadataReference;
};
