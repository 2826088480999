import type {
  GetMatchedQualitiesQuery,
  Quality,
  Skill,
} from 'src/graphql/graphql.ts';

type GetMatchedQualitiesQueryQualities = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GetMatchedQualitiesQuery>['jobDetails']
    >['personalised']
  >['matchedSkills']
>;
type GetMatchedQualitiesQuerySkills2 = NonNullable<
  NonNullable<GetMatchedQualitiesQuery>['viewer']
>['skills2'];

export type MatchedQualities = {
  matched?: Skill[];
  inferred?: Skill[];
  unmatched?: Skill[];
};

const QUALITIES_LIMIT = 10;

const mapToSkill = ({ ontologyId, displayLabel }: Quality): Skill => ({
  keyword: { ontologyId, text: displayLabel },
});

const filterEmpty = (quality: Quality | null) => quality !== null;

// accept qualities as Quality[] arrays and map to Skill Arrays
export const map = ({
  matched,
  inferred,
  unmatched,
}: GetMatchedQualitiesQueryQualities = {}): MatchedQualities => ({
  matched: matched?.filter(filterEmpty).map(mapToSkill),
  inferred: inferred?.filter(filterEmpty).map(mapToSkill),
  unmatched: unmatched?.filter(filterEmpty).map(mapToSkill),
});

const getSkillIds = (skills: Skill[]) =>
  skills.map((s) => s.keyword?.ontologyId).filter((s) => typeof s === 'string');

export const sync = (
  qualities: MatchedQualities,
  skills?: GetMatchedQualitiesQuerySkills2,
) => {
  if (qualities && skills) {
    const skillIds = getSkillIds(skills);

    const matched: Skill[] = [],
      inferred: Skill[] = [],
      unmatched: Skill[] = [];

    const createVerifier =
      (
        verifier: (id: string) => boolean,
        skillsMatchingVerfier: Skill[],
        skillsNotMatchingVerifer: Skill[],
      ) =>
      (skill: Skill) => {
        const id = skill.keyword.ontologyId;

        const collection =
          id && verifier(id) ? skillsMatchingVerfier : skillsNotMatchingVerifer;
        collection.push(skill);
      };

    qualities.matched?.forEach(
      createVerifier((id) => skillIds.includes(id), matched, unmatched),
    );
    qualities.inferred?.forEach(
      createVerifier((id) => !skillIds.includes(id), inferred, matched),
    );
    qualities.unmatched?.forEach(
      createVerifier((id) => !skillIds.includes(id), unmatched, matched),
    );

    return { matched, inferred, unmatched };
  }

  return qualities;
};

export const limitQualities = (
  qualities: MatchedQualities,
): MatchedQualities => {
  const { matched, inferred, unmatched } = qualities;
  const matchedLength = matched?.length ?? 0;
  const inferredLength = inferred?.length ?? 0;
  const unmatchedLength = unmatched?.length ?? 0;

  if (matchedLength > QUALITIES_LIMIT) {
    return {
      matched: matched?.slice(0, QUALITIES_LIMIT),
      inferred: [],
      unmatched: [],
    };
  }
  if (matchedLength + inferredLength > QUALITIES_LIMIT) {
    return {
      matched,
      inferred: inferred?.slice(0, QUALITIES_LIMIT - matchedLength),
      unmatched: [],
    };
  }
  if (matchedLength + inferredLength + unmatchedLength > QUALITIES_LIMIT) {
    return {
      matched,
      inferred,
      unmatched: unmatched?.slice(
        0,
        QUALITIES_LIMIT - matchedLength - inferredLength,
      ),
    };
  }
  return qualities;
};
