export default () => (
  <svg
    width="128"
    height="128"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160 160"
  >
    <path
      fill="#ee399e"
      d="m136.72,119.35l-61.19-61.19c1.08-3.01,1.71-6.24,1.78-9.61.35-16.77-12.95-30.66-29.73-31.01-3.14-.06-6.16.34-9.03,1.17l13.77,14.37c2.28,2.38,2.2,6.2-.18,8.48l-12.41,11.89c-2.38,2.28-6.2,2.2-8.48-.18h0s-13.42-14-13.42-14c-.75,2.55-1.21,5.21-1.27,7.99-.35,16.77,12.95,30.66,29.73,31.01,3.86.08,7.57-.57,11-1.81l61.1,61.23c5.1,5.1,13.23,5.1,18.33,0,5.1-5.1,5.1-13.23,0-18.33Zm-9.51,14.2c-3.18,0-5.65-2.65-5.65-5.66s2.65-5.66,5.65-5.66,5.66,2.65,5.66,5.66-2.47,5.66-5.66,5.66Z"
    />
    <polygon
      fill="#b6c7e0"
      points="143.44 29.19 137.14 22.88 124.53 29.19 121.86 37.18 72.42 86.63 79.7 93.9 129.14 44.45 137.14 41.79 143.44 29.19"
    />
    <path
      fill="#0d3880"
      d="m89.93,91.74l-15.35-15.35-5.1,5.1-2.56-2.56-40.95,40.95c-2.83,2.83-2.83,7.41,0,10.24l10.23,10.23c2.83,2.83,7.41,2.83,10.24,0l40.95-40.95-2.56-2.56,5.1-5.1Z"
    />
    <path
      fill="#7795c2"
      d="m43.02,132.98c-.51,0-1.02-.2-1.41-.59-.78-.78-.78-2.05,0-2.83l30.81-30.81c.78-.78,2.05-.78,2.83,0,.78.78.78,2.05,0,2.83l-30.81,30.81c-.39.39-.9.59-1.41.59Z"
    />
    <path
      fill="#7795c2"
      d="m35.34,125.3c-.51,0-1.02-.2-1.41-.59-.78-.78-.78-2.05,0-2.83l30.81-30.81c.78-.78,2.05-.78,2.83,0,.78.78.78,2.05,0,2.83l-30.81,30.81c-.39.39-.9.59-1.41.59Z"
    />
  </svg>
);
