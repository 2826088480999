import { useMelwaysLink } from '@seek/melways-react';
import type { TranslationKeys } from '@vocab/core';
import { useTranslations } from '@vocab/react';
import { TextLink } from 'braid-design-system';
import { useCallback } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import { MessageWrapper } from '../MessageWrapper/MessageWrapper';

import translations from './.vocab';

type TranslationKey = TranslationKeys<typeof translations>;

type ErrorStateProps = {
  section: 'recommendedJobs' | 'savedJobs' | 'savedSearches';
};

const getDataForSection = (
  section: ErrorStateProps['section'],
): {
  path: string;
  translationKey: TranslationKey;
  trackingLinkName: string;
} => {
  switch (section) {
    case 'recommendedJobs':
      return {
        path: '/recommended',
        translationKey: 'The Recommended jobs summary is currently unavailable',
        trackingLinkName: 'dashboard-all-recommended-jobs-click',
      };
    case 'savedJobs':
      return {
        path: '/my-activity/saved-jobs',
        translationKey: 'The Saved jobs summary is currently unavailable',
        trackingLinkName: 'dashboard-all-saved-jobs-click',
      };
    case 'savedSearches':
      return {
        path: '/my-activity/saved-searches',
        translationKey: 'The Saved searches summary is currently unavailable',
        trackingLinkName: 'dashboard-all-saved-searches-click',
      };
  }
};

export const ErrorState = ({ section }: ErrorStateProps) => {
  const { path, trackingLinkName, translationKey } = getDataForSection(section);
  const errorStateHref = useMelwaysLink({
    path,
  });
  const { t } = useTranslations(translations);
  const analytics = useAnalyticsFacade();
  const textLinkOnClick = useCallback(() => {
    analytics.linkClicked({
      linkName: trackingLinkName,
      href: errorStateHref,
    });
  }, [analytics, trackingLinkName, errorStateHref]);

  return (
    <MessageWrapper>
      {t(translationKey, {
        Link: (children) => (
          <TextLink
            weight="weak"
            href={errorStateHref}
            onClick={textLinkOnClick}
          >
            {children}
          </TextLink>
        ),
      })}
    </MessageWrapper>
  );
};
