import { CardsStack } from '../SharedComponent/CardsStack/CardsStack';
import { JobCardSkeleton } from '../SharedComponent/JobCard/JobCardSkeleton';

interface LoadingRecommendationsProps {
  length: number;
  keyPrefix: string;
}

export const LoadingRecommendations: React.FC<LoadingRecommendationsProps> = ({
  length,
  keyPrefix,
}) => {
  const skeletonCards = Array.from({ length }).map((_, index) => (
    <JobCardSkeleton key={`${keyPrefix}_${index}`} />
  ));
  return <CardsStack>{skeletonCards}</CardsStack>;
};
