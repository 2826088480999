import type { SafetyHelmetProps } from 'src/components/SafetyHelmet/SafetyHelmet';
import { useAppConfig } from 'src/config/appConfig';

import { generateMetaDescription } from '../utils';

import { jobstreetAlternateLinks } from './alternateLinks';
import { useDescriptionContent } from './description/content';

export const useHomePageHead = () => {
  const { brand } = useAppConfig();
  const descriptionContent = useDescriptionContent();
  const tags: SafetyHelmetProps = {
    script: [],
    meta: generateMetaDescription(descriptionContent),
    ...(brand === 'jobstreet' ? { link: jobstreetAlternateLinks } : {}),
  };

  return tags;
};
