
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJVbnNhdmUiLCJTYXZlIjoiU2F2ZSIsIllvdSBoYXZlIGFwcGxpZWQgZm9yIHRoaXMgam9iIjoiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJCYXRhbGthbiBwZW55aW1wYW5hbiIsIlNhdmUiOiJTaW1wYW4iLCJZb3UgaGF2ZSBhcHBsaWVkIGZvciB0aGlzIGpvYiI6IkthbXUgdGVsYWggbWVuZ2lyaW0gbGFtYXJhbiB1bnR1ayBsb3dvbmdhbiBrZXJqYSBpbmkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJCYXRhbGthbiBwZW55aW1wYW5hbiIsIlNhdmUiOiJTaW1wYW4iLCJZb3UgaGF2ZSBhcHBsaWVkIGZvciB0aGlzIGpvYiI6IkthbXUgdGVsYWggbWVuZ2lyaW0gbGFtYXJhbiB1bnR1ayBsb3dvbmdhbiBrZXJqYSBpbmkifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiLguKLguIHguYDguKXguLTguIHguIHguLLguKPguJrguLHguJnguJfguLbguIEiLCJTYXZlIjoi4Lia4Lix4LiZ4LiX4Li24LiBIiwiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IiOiLguITguLjguJPguKrguKHguLHguITguKPguIfguLLguJnguJnguLXguYnguYHguKXguYnguKcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiLguKLguIHguYDguKXguLTguIHguIHguLLguKPguJrguLHguJnguJfguLbguIEiLCJTYXZlIjoi4Lia4Lix4LiZ4LiX4Li24LiBIiwiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IiOiLguITguLjguJPguKrguKHguLHguITguKPguIfguLLguJnguJnguLXguYnguYHguKXguYnguKcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJCYXRhbGthbiBwZW55aW1wYW5hbiIsIlNhdmUiOiJTaW1wYW4iLCJZb3UgaGF2ZSBhcHBsaWVkIGZvciB0aGlzIGpvYiI6IkthbXUgdGVsYWggbWVuZ2lyaW0gbGFtYXJhbiB1bnR1ayBsb3dvbmdhbiBrZXJqYSBpbmkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJCYXRhbGthbiBwZW55aW1wYW5hbiIsIlNhdmUiOiJTaW1wYW4iLCJZb3UgaGF2ZSBhcHBsaWVkIGZvciB0aGlzIGpvYiI6IkthbXUgdGVsYWggbWVuZ2lyaW0gbGFtYXJhbiB1bnR1ayBsb3dvbmdhbiBrZXJqYSBpbmkifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiLguKLguIHguYDguKXguLTguIHguIHguLLguKPguJrguLHguJnguJfguLbguIEiLCJTYXZlIjoi4Lia4Lix4LiZ4LiX4Li24LiBIiwiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IiOiLguITguLjguJPguKrguKHguLHguITguKPguIfguLLguJnguJnguLXguYnguYHguKXguYnguKcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiLguKLguIHguYDguKXguLTguIHguIHguLLguKPguJrguLHguJnguJfguLbguIEiLCJTYXZlIjoi4Lia4Lix4LiZ4LiX4Li24LiBIiwiWW91IGhhdmUgYXBwbGllZCBmb3IgdGhpcyBqb2IiOiLguITguLjguJPguKrguKHguLHguITguKPguIfguLLguJnguJnguLXguYnguYHguKXguYnguKcifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJbx5nguIHguLXguYnFocSDxIPEg%2BG5veG6veG6veG6vV0iLCJTYXZlIjoiW8WgxIPEg%2BG5veG6veG6vV0iLCJZb3UgaGF2ZSBhcHBsaWVkIGZvciB0aGlzIGpvYiI6IlvDncO2w7bDtseax5rHmiDhuKnEg8SDxIPhub3hur3hur3hur0gxIPEg8SDxqXGpcaaw6zDrMOs4bq94bq94bq9xowgxpLDtsO2w7bFmSDhua%2FhuKnDrMOsw6zFoSDEtcO2w7bDtsOfXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJVbnNhdmUiOiJbx5nguIHguLXguYnFocSDxIPEg%2BG5veG6veG6veG6vV0iLCJTYXZlIjoiW8WgxIPEg%2BG5veG6veG6vV0iLCJZb3UgaGF2ZSBhcHBsaWVkIGZvciB0aGlzIGpvYiI6IlvDncO2w7bDtseax5rHmiDhuKnEg8SDxIPhub3hur3hur3hur0gxIPEg8SDxqXGpcaaw6zDrMOs4bq94bq94bq9xowgxpLDtsO2w7bFmSDhua%2FhuKnDrMOsw6zFoSDEtcO2w7bDtsOfXSJ9!"
        )
      )
      });
  
      export { translations as default };
    