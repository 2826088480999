import { Badge, Box, Inline } from 'braid-design-system';
import type { ComponentProps } from 'react';

export interface JobBadgeProps {
  label: string;
  tone: ComponentProps<typeof Badge>['tone'];
  data?: ComponentProps<typeof Badge>['data'];
}

const JobBadge = ({ tone, label, data }: JobBadgeProps) => (
  <Box pointerEvents="none">
    <Badge tone={tone} data={data}>
      {label}
    </Badge>
  </Box>
);

export const JobBadges = ({ badges }: { badges: JobBadgeProps[] }) =>
  badges.length ? (
    <Inline space="xxsmall">
      {badges.map(({ label, tone, data }, index) => (
        <JobBadge key={index} label={label} tone={tone} data={data} />
      ))}
    </Inline>
  ) : null;
