import React, { useEffect } from 'react';

import {
  MINIMUM_MATCHES_TO_DISPLAY,
  type useJDVSkillsMatchProps,
} from 'src/components/JobDetailsPage/useJDVSkillsMatchProps';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { selectFeatureFlag } from 'src/store/featureFlags/selectors';
import { useSelector } from 'src/store/react';

const currentPageSection = 'skills match';

const getMatchedSkillsVisibilityStatus = (skillsCount: number) => {
  if (skillsCount >= MINIMUM_MATCHES_TO_DISPLAY) {
    return 'null: no error';
  }
  if (skillsCount > 0) {
    return 'hidden: less than 3 skills';
  }
  return 'hidden: unmatched';
};

export const useSkillsMatchTracking = ({
  qualities,
}: {
  qualities: ReturnType<typeof useJDVSkillsMatchProps>;
}) => {
  const enableMatchedQualities = useSelector(
    selectFeatureFlag('matchedQualities'),
  );
  const analyticsFacade = useAnalyticsFacade();
  const { isLoadingQualities, shouldShowSkillsMatch, skillsCount, error } =
    qualities ?? { isLoadingQualities: null, shouldShowSkillsMatch: null };
  const [isTrackingSent, setIsTrackingSent] = React.useState(false);
  useEffect(() => {
    if (!enableMatchedQualities) {
      return;
    }
    if (isTrackingSent || isLoadingQualities) {
      return;
    }
    if (error) {
      analyticsFacade.skillsMatchLoadFailed({
        currentPageSection,
        isLoggedIn: true,
        matchedSkillsVisibilityStatus:
          'network error: trouble loading matched skills',
      });
    } else {
      analyticsFacade.skillsMatchLoadSucceeded({
        currentPageSection,
        isLoggedIn: true,
        areMatchedSkillsVisible: shouldShowSkillsMatch!,
        matchedSkillsVisibilityStatus:
          getMatchedSkillsVisibilityStatus(skillsCount),
      });
    }
    setIsTrackingSent(true);
  }, [
    analyticsFacade,
    error,
    isLoadingQualities,
    isTrackingSent,
    shouldShowSkillsMatch,
    skillsCount,
    enableMatchedQualities,
  ]);
};
