import { Stack } from 'braid-design-system';
import type { ComponentProps } from 'react';

export const CardsStack = ({
  children,
  data,
}: {
  children: ComponentProps<typeof Stack>['children'];
  data?: ComponentProps<typeof Stack>['data'];
}) => (
  <Stack space="xsmall" data={data}>
    {children}
  </Stack>
);
