import { useTranslations } from '@vocab/react';
import { Stack, Heading } from 'braid-design-system';

import { CardsStack } from '../SharedComponent/CardsStack/CardsStack';
import { ErrorState } from '../SharedComponent/ErrorState/ErrorState';
import JobCard from '../SharedComponent/JobCard/JobCard';
import { JobCardSkeleton } from '../SharedComponent/JobCard/JobCardSkeleton';
import { MessageWrapper } from '../SharedComponent/MessageWrapper/MessageWrapper';
import { ViewAllLink } from '../SharedComponent/ViewAllLink/ViewAllLink';

import translations from './.vocab';
import { useSavedJobs } from './useSavedJobs';

const LOADING_SKELETON_COUNT = 3;
const HOME_SAVED_JOB_VIEW_ORIGIN = 'saved-homepage';

const SavedJobsLoadingState = () => (
  <CardsStack>
    {new Array(LOADING_SKELETON_COUNT).fill(null).map((_, index) => (
      <JobCardSkeleton key={index} />
    ))}
  </CardsStack>
);

const SavedJobsEmptyState = () => {
  const { t } = useTranslations(translations);
  return <MessageWrapper>{t('Use the Save...')}</MessageWrapper>;
};

const SavedJobsContent = ({
  jobs,
  error,
  loading,
}: Pick<ReturnType<typeof useSavedJobs>, 'jobs' | 'error' | 'loading'>) => {
  if (loading) {
    return <SavedJobsLoadingState />;
  }

  if (error) {
    return <ErrorState section="savedJobs" />;
  }

  if (jobs && jobs.length > 0) {
    return (
      <CardsStack data={{ automation: 'savedJobs' }}>
        {jobs.map((job, index) => (
          <JobCard
            key={`savedJob_${job.id}`}
            testAutomation={{ jobCardLink: `savedJobLink_${job.id}` }}
            jobInfo={job}
            analytics={{
              linkName: 'dashboard-save-job-click',
              linkSection: 'Saved Jobs',
              viewOrigin: HOME_SAVED_JOB_VIEW_ORIGIN,
              position: index + 1,
            }}
          />
        ))}
      </CardsStack>
    );
  }

  return <SavedJobsEmptyState />;
};

const SavedJobs = () => {
  const { t } = useTranslations(translations);
  const { jobs, totalCount, error, loading } = useSavedJobs();

  return (
    <Stack space="small">
      <Heading level="4" weight="weak">
        {t('Saved jobs')}
      </Heading>
      <Stack space="small">
        <SavedJobsContent jobs={jobs} error={error} loading={loading} />
        <ViewAllLink loading={loading} count={totalCount} section="savedJobs" />
      </Stack>
    </Stack>
  );
};

export default SavedJobs;
