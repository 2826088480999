import { StandaloneCampaign } from '../StandaloneCampaign/StandaloneCampaign';

import useSeekretCampaignContent from './useSeekretCampaignContent';

const SeekretCampaignBanner = () => {
  const props = useSeekretCampaignContent();

  return <StandaloneCampaign {...props} />;
};

export default SeekretCampaignBanner;
