import { useTranslations } from '@vocab/react';
import { Button, IconRefresh } from 'braid-design-system';
import { useMemo } from 'react';

import { IllustratedError } from 'src/components/IllustratedError/IllustratedError';
import Tools from 'src/components/IllustratedError/Tools';
import {
  useStopTimerOnComponentVisible,
  useStopTimerOnComponentVisibleControl,
} from 'src/modules/hooks/useStopTimerOnComponentVisible';

import translations from './.vocab';

interface ReloadButtonProps {
  onReloadClick: () => void;
  text: string;
}

const ReloadButton = ({ onReloadClick, text }: ReloadButtonProps) => (
  <Button
    onClick={() => {
      onReloadClick();
    }}
    tone="formAccent"
    variant="ghost"
    icon={<IconRefresh />}
  >
    {text}
  </Button>
);

const JobDetailError = ({ onReloadClick }: { onReloadClick: () => void }) => {
  const { t } = useTranslations(translations);
  const reloadBtn = useMemo(
    () => <ReloadButton onReloadClick={onReloadClick} text={t('Reload')} />,
    [onReloadClick, t],
  );

  const ref = useStopTimerOnComponentVisible({
    actionName: 'JobListItemClickToJDV:Visible',
    additionalTags: ['error-outcome'],
  });
  useStopTimerOnComponentVisibleControl({
    actionName: 'JobListItemClickToJDV:Visible:Control',
    additionalTags: ['error-outcome'],
  });

  return (
    <IllustratedError
      ref={ref}
      heading={t("We couldn't load this job")}
      description={
        <>
          {t(
            "We can't load this right now but we're doing our best to fix it.",
          )}
          <br />
          {t('Try reloading or select another job.')}
        </>
      }
      Illustration={Tools}
      dataAutomation="JDVError"
      actionButton={reloadBtn}
    />
  );
};

export default JobDetailError;
