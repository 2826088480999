import type { UrlLocation } from '@seek/chalice-types';
import { Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import { TextLink } from 'src/components/NavLink/NavLink';
import { useDispatch } from 'src/store/react';
import { showPoeaCountryPickerFromNavigation } from 'src/store/search';
import type { TypedDispatch } from 'src/store/types';

export interface QuickSearchLinkProps {
  children?: string | ReactNode;
  linkProps: {
    ['aria-label']?: string;
    ['data-automation']?: string;
    analytics?: string;
    location: UrlLocation | string;
    rel?: string;
  };
}

const handlePoeaSpecificLinkClick = (
  dispatch: TypedDispatch,
  children?: string | ReactNode,
) => {
  const poeaKeywords = ['Work Abroad Jobs', 'POEA Jobs'];
  if (typeof children === 'string' && poeaKeywords.includes(children)) {
    return dispatch(showPoeaCountryPickerFromNavigation(true));
  }
};

export const QuickSearchLink = ({
  children,
  linkProps,
}: QuickSearchLinkProps) => {
  const dispatch = useDispatch();
  return (
    <Text size="small" tone="secondary" component="li">
      <TextLink
        weight="weak"
        {...linkProps}
        onClick={() => handlePoeaSpecificLinkClick(dispatch, children)}
      >
        {children}
      </TextLink>
    </Text>
  );
};
