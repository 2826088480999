import { IconRenderer } from 'braid-design-system';

export const HourglassIcon = () => (
  <>
    <IconRenderer>
      {({ className }) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
          className={className}
          aria-hidden
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 1C2.22386 1 2 1.22386 2 1.5C2 1.77614 2.22386 2 2.5 2H3V3.5C3 4.54349 3.53275 5.46254 4.3411 6C3.53275 6.53746 3 7.45651 3 8.5V10H2.5C2.22386 10 2 10.2239 2 10.5C2 10.7761 2.22386 11 2.5 11H9.5C9.77614 11 10 10.7761 10 10.5C10 10.2239 9.77614 10 9.5 10H9V8.5C9 7.45651 8.46725 6.53746 7.6589 6C8.46725 5.46254 9 4.54349 9 3.5V2H9.5C9.77614 2 10 1.77614 10 1.5C10 1.22386 9.77614 1 9.5 1H2.5ZM6 5.5C7.10457 5.5 8 4.60457 8 3.5V2H4V3.5C4 4.60457 4.89543 5.5 6 5.5Z"
          />
        </svg>
      )}
    </IconRenderer>
  </>
);
