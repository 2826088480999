import type { JobTagType } from '@seek/chalice-types';
import { type Badge, Box, Inline, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { ClockIcon } from '../icons/ClockIcon';
import { HourglassIcon } from '../icons/HourglassIcon';

export interface JobLabelProps {
  label: string;
  tone: ComponentProps<typeof Badge>['tone'];
  type: JobTagType;
}

const jobBadgeTypeToIconMap = (type: JobTagType) => {
  switch (type) {
    case 'EXPIRES_SOON':
      return <HourglassIcon />;
    case 'EARLY_APPLICANT':
      return <ClockIcon />;
    default:
      return null;
  }
};

const JobLabel = ({ type, tone, label }: JobLabelProps) => {
  const iconToDisplay = jobBadgeTypeToIconMap(type);
  if (!iconToDisplay) return null;
  return (
    <Box pointerEvents="none">
      <Text icon={iconToDisplay} size="xsmall" tone={tone} weight="medium">
        {label}
      </Text>
    </Box>
  );
};

export const JobLabels = ({
  labels,
}: {
  labels: Record<string, JobLabelProps>;
}) => {
  if (Object.keys(labels).length === 0) {
    return null;
  }

  return (
    <Inline space="xxsmall">
      {Object.values(labels).map(({ tone, type, label }, index) => (
        <JobLabel key={index} label={label} tone={tone} type={type} />
      ))}
    </Inline>
  );
};
