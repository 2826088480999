import { useMelwaysLink } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import { Box, ButtonLink, Tiles } from 'braid-design-system';
import { useCallback, type ReactNode } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useIsHomepageMobileWebRedesignEnabled } from 'src/store/featureFlags/hooks';
import { isMobileWebRedesignFeatureFlagReady } from 'src/store/featureFlags/selectors';
import { useSelector } from 'src/store/react';

import { getViewAllLinkData } from '../SharedComponent/ViewAllLink/ViewAllLink';

import translations from './.vocab';

import { loadingState } from './MyActivityTiles.css';

const MyActivityButton = ({
  section,
  children,
}: {
  section: 'savedJobs' | 'savedSearches';
  children: ReactNode;
}) => {
  const {
    viewAllLinkPath,
    analytics: { linkName, linkSection },
  } = getViewAllLinkData(section);
  const viewAllLinkHref = useMelwaysLink({ path: viewAllLinkPath });
  const analytics = useAnalyticsFacade();

  const textLinkOnClick = useCallback(() => {
    analytics.linkClicked({
      linkContext: { linkSection },
      linkName,
      href: viewAllLinkHref,
    });
  }, [analytics, linkName, linkSection, viewAllLinkHref]);

  return (
    <ButtonLink
      variant="soft"
      size="small"
      tone="neutral"
      onClick={textLinkOnClick}
      href={viewAllLinkHref}
    >
      {children}
    </ButtonLink>
  );
};

/**
 * This is temporary loading state for MyActivityTiles.
 * This should be removed in the experiment is finished since
 * the reason why this exist is because we need to wait
 * for the feature flag to be ready before we can render.
 */
const MyActivityTilesLoadingState = () => (
  <Box
    display={{ mobile: 'block', tablet: 'flex', desktop: 'none' }}
    width="full"
  >
    <Tiles columns={2} space="xsmall">
      <Box
        component="span"
        display="block"
        borderRadius="standard"
        background="neutralLight"
        className={loadingState}
      />
      <Box
        component="span"
        display="block"
        borderRadius="standard"
        background="neutralLight"
        className={loadingState}
      />
    </Tiles>
  </Box>
);

export const MyActivityTiles = () => {
  const { t } = useTranslations(translations);
  const isMobileWebExperimentReady = useSelector(
    isMobileWebRedesignFeatureFlagReady,
  );
  const isHomepageMobileWebRedesignEnabled =
    useIsHomepageMobileWebRedesignEnabled();

  if (!isMobileWebExperimentReady) {
    return <MyActivityTilesLoadingState />;
  }

  if (!isHomepageMobileWebRedesignEnabled) {
    return null;
  }

  return (
    <Box display={{ mobile: 'block', tablet: 'flex', desktop: 'none' }}>
      <Tiles columns={2} space="xsmall">
        <MyActivityButton section="savedSearches">
          {t('Saved searches')}
        </MyActivityButton>
        <MyActivityButton section="savedJobs">
          {t('Saved jobs')}
        </MyActivityButton>
      </Tiles>
    </Box>
  );
};
