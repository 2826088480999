
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6Ik1vcmUgam9icyBmb3IgeW91IiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5VcGRhdGUgeW91ciBwcm9maWxlPC9MaW5rPiBvciBzdGFydCBzZWFyY2hpbmcgZm9yIGpvYnMgdG8gZ2V0IHBlcnNvbmFsaXNlZCBqb2IgcmVjb21tZW5kYXRpb25zIGhlcmUuIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJMZWJpaCBiYW55YWsgcGVrZXJqYWFuIHVudHVrIEFuZGEiLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlBlcmJhcnVpIHByb2ZpbCBBbmRhPC9MaW5rPiBhdGF1IG11bGFpIG1lbmNhcmkgbG93b25nYW4gdW50dWsgbWVuZGFwYXRrYW4gcmVrb21lbmRhc2kgbG93b25nYW4geWFuZyBkaXBlcnNvbmFsaXNhc2kgZGkgc2luaS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJMZWJpaCBiYW55YWsgcGVrZXJqYWFuIHVudHVrIEFuZGEiLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlBlcmJhcnVpIHByb2ZpbCBBbmRhPC9MaW5rPiBhdGF1IG11bGFpIG1lbmNhcmkgbG93b25nYW4gdW50dWsgbWVuZGFwYXRrYW4gcmVrb21lbmRhc2kgbG93b25nYW4geWFuZyBkaXBlcnNvbmFsaXNhc2kgZGkgc2luaS4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIk1vcmUgam9icyBmb3IgeW91Ijoi4LiH4Liy4LiZ4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz7guK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ%2FguKXguYzguILguK3guIfguITguLjguJM8L0xpbms%2BIOC4q%2BC4o%2BC4t%2BC4reC5gOC4o%2BC4tOC5iOC4oeC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC4o%2BC4seC4muC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4s%2BC4l%2BC4teC5iOC4hOC4seC4lOC4quC4o%2BC4o%2BC4oeC4suC5gOC4nuC4t%2BC5iOC4reC4hOC4uOC4k%2BC4l%2BC4teC5iOC4meC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIk1vcmUgam9icyBmb3IgeW91Ijoi4LiH4Liy4LiZ4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz7guK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ%2FguKXguYzguILguK3guIfguITguLjguJM8L0xpbms%2BIOC4q%2BC4o%2BC4t%2BC4reC5gOC4o%2BC4tOC5iOC4oeC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC4o%2BC4seC4muC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4s%2BC4l%2BC4teC5iOC4hOC4seC4lOC4quC4o%2BC4o%2BC4oeC4suC5gOC4nuC4t%2BC5iOC4reC4hOC4uOC4k%2BC4l%2BC4teC5iOC4meC4teC5iCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJMZWJpaCBiYW55YWsgcGVrZXJqYWFuIHVudHVrIEFuZGEiLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlBlcmJhcnVpIHByb2ZpbCBBbmRhPC9MaW5rPiBhdGF1IG11bGFpIG1lbmNhcmkgbG93b25nYW4gdW50dWsgbWVuZGFwYXRrYW4gcmVrb21lbmRhc2kgbG93b25nYW4geWFuZyBkaXBlcnNvbmFsaXNhc2kgZGkgc2luaS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJMZWJpaCBiYW55YWsgcGVrZXJqYWFuIHVudHVrIEFuZGEiLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlBlcmJhcnVpIHByb2ZpbCBBbmRhPC9MaW5rPiBhdGF1IG11bGFpIG1lbmNhcmkgbG93b25nYW4gdW50dWsgbWVuZGFwYXRrYW4gcmVrb21lbmRhc2kgbG93b25nYW4geWFuZyBkaXBlcnNvbmFsaXNhc2kgZGkgc2luaS4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIk1vcmUgam9icyBmb3IgeW91Ijoi4LiH4Liy4LiZ4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz7guK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ%2FguKXguYzguILguK3guIfguITguLjguJM8L0xpbms%2BIOC4q%2BC4o%2BC4t%2BC4reC5gOC4o%2BC4tOC5iOC4oeC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC4o%2BC4seC4muC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4s%2BC4l%2BC4teC5iOC4hOC4seC4lOC4quC4o%2BC4o%2BC4oeC4suC5gOC4nuC4t%2BC5iOC4reC4hOC4uOC4k%2BC4l%2BC4teC5iOC4meC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIk1vcmUgam9icyBmb3IgeW91Ijoi4LiH4Liy4LiZ4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz7guK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ%2FguKXguYzguILguK3guIfguITguLjguJM8L0xpbms%2BIOC4q%2BC4o%2BC4t%2BC4reC5gOC4o%2BC4tOC5iOC4oeC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC4o%2BC4seC4muC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4meC4sOC4meC4s%2BC4l%2BC4teC5iOC4hOC4seC4lOC4quC4o%2BC4o%2BC4oeC4suC5gOC4nuC4t%2BC5iOC4reC4hOC4uOC4k%2BC4l%2BC4teC5iOC4meC4teC5iCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJb4bqexIPEg8SDxaHhur3hur3hur3GjCDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxqXFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0sIMS1w7bDtsO2w58g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDEg8SDxIPguIHguLXguYnGjCDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhLl0iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IlvhuYLDtsO2xZnhur3hur0gxLXDtsO2w5%2FFoSDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiJbPExpbms%2Bx5nGpcaMxIPEg%2BG5r%2BG6veG6vSDDvcO9w7bDtseax5rFmSDGpcWZw7bDtsaSw6zDrMaa4bq94bq9PC9MaW5rPiDDtsO2w7bFmSDFoeG5r8SDxIPEg8WZ4bmvIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qcOsw6zDrOC4geC4teC5icSjIMaSw7bDtsO2xZkgxLXDtsO2w7bDn8WhIOG5r8O2w7bDtiDEo%2BG6veG6veG6veG5ryDGpeG6veG6veG6vcWZxaHDtsO2w7bguIHguLXguYnEg8SDxIPGmsOsw6zDrMWh4bq94bq94bq9xowgxLXDtsO2w7bDnyDFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoSDhuKnhur3hur3hur3FmeG6veG6veG6vS5dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJb4bqexIPEg8SDxaHhur3hur3hur3GjCDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxqXFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0sIMS1w7bDtsO2w58g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDEg8SDxIPguIHguLXguYnGjCDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhLl0iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IlvhuYLDtsO2xZnhur3hur0gxLXDtsO2w5%2FFoSDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiJbPExpbms%2Bx5nGpcaMxIPEg%2BG5r%2BG6veG6vSDDvcO9w7bDtseax5rFmSDGpcWZw7bDtsaSw6zDrMaa4bq94bq9PC9MaW5rPiDDtsO2w7bFmSDFoeG5r8SDxIPEg8WZ4bmvIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qcOsw6zDrOC4geC4teC5icSjIMaSw7bDtsO2xZkgxLXDtsO2w7bDn8WhIOG5r8O2w7bDtiDEo%2BG6veG6veG6veG5ryDGpeG6veG6veG6vcWZxaHDtsO2w7bguIHguLXguYnEg8SDxIPGmsOsw6zDrMWh4bq94bq94bq9xowgxLXDtsO2w7bDnyDFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoSDhuKnhur3hur3hur3FmeG6veG6veG6vS5dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    